import {useEffect} from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { useClient } from "./context/ClientContext";
import { Alert, Snackbar } from "@mui/material";
import { useNotifications } from "./context/NotificationContext";
import { NotificationDataTypes } from "./types/NotificationTypes";

const Layout = () => {
  const { authorized } = useClient();
  const { notificationState, dispatch } = useNotifications();

  useEffect(() => {
    console.log('authorized', authorized)
  }, [authorized])


  return (
    <>
      <BrowserRouter basename="/">
        <Router />
        <Snackbar
          open={notificationState.toast.open}
          onClose={() => dispatch({type: NotificationDataTypes.CLEAR_TOAST})}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
        >
          <Alert severity={notificationState.toast.status} >{notificationState.toast.message}</Alert>
        </Snackbar>
      </BrowserRouter>
    </>
  );
};

export default Layout;
