type messageProps =
  | "UPLOAD_COMPLETED"
  | "UPLOAD_FAILED"
  | "CHATBOT_READY"
  | "SERVER_DISCONNECT"
  | "RESYNC_COMPLETED" 
  | "RESYNCE_FAILED"
  | "FILE_DELETED"
  | "FILE_ACTIVATED"
  | "FILE_DEACTIVATED"
  | "STYLE_UPDATED"
  | "STYLE_UPDATE_FAILED"
  | "NEW_FEATURE";

export const toastMessages = (message: messageProps, name?: string) => {
  const toastCopy = {
    UPLOAD_COMPLETED: {
      message: name ? `${name} has completed uploading.` : "A file has completed uploading.",
      status: "success",
    },
    UPLOAD_FAILED: { message: name ? `${name} has has failed to upload. Please try again.` : "A file has failed to upload", status: "error" },
    CHATBOT_READY: {
      message: "Your chatbot is ready for use!",
      status: "info",
    },
    SERVER_DISCONNECT: {
      message: "Unable to connect to the server. Please reload to try again.",
      status: "error",
    },
    RESYNC_COMPLETED: {
        message: "Resyncing completed.",
        status: "success"
    },
    RESYNCE_FAILED: {
        message: "Resyncing failed. Please try again.",
        status: "error"
    },
    FILE_DELETED: {
        message: "File deletion complete. Reload assistant",
        status: "success"
    },
    FILE_ACTIVATED: {
        message: "File activated successfully. Reload assistant",
        status: "success"
    },
    FILE_DEACTIVATED: {
        message: "File deactivated. Reload assistant",
        status: "success"
    },
    STYLE_UPDATED: {
        message: "Assistant updated successfully",
        status: "success"
    },
    STYLE_UPDATE_FAILED: {
        message: "Update failed. Try again",
        status: "error"
    },
    NEW_FEATURE: {
        message: "",
        status: "info"
    }
  };

  if (toastCopy[message]) {
    return {
      message: toastCopy[message].message,
      status: toastCopy[message].status as 'error' | 'success' | 'info' | 'warning',
    };
  }

  return {
    message: "",
    status: "error" as 'error' | 'success' | 'info' | 'warning',
  };
};
