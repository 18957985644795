// This context will handle any interactions that control any type of
// notifications including toasts/snackbars or notifications in the future
// as well as confirmation/alert modals for various scenarios

import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  NotificationState,
  NotificationDataTypes,
  NotificationActionTypes,
  NotificationContext,
} from "../types/NotificationTypes";
import { toastMessages } from "../utils/toastMessages";
import { useClient } from "./ClientContext";

type messageProps =
  | "UPLOAD_COMPLETED"
  | "UPLOAD_FAILED"
  | "CHATBOT_READY"
  | "SERVER_DISCONNECT"
  | "RESYNC_COMPLETED"
  | "FILE_DELETED"
  | "FILE_DEACTIVATED"
  | "STYLE_UPDATED"
  | "STYLE_UPDATE_FAILED"
  | "NEW_FEATURE";

const NotificationCtx = createContext({});

const initialState: NotificationState = {
  toast: { open: false, message: "", status: "info" },
};

const notificationReducer = (
  state: NotificationState = initialState,
  action: NotificationActionTypes.AllReducer
) => {
  switch (action.type) {
    case NotificationDataTypes.SET_TOAST:
      return { ...state, toast: action.payload };
    case NotificationDataTypes.CLEAR_TOAST:
      return {
        ...state,
        toast: { open: false, message: "", status: state.toast.status },
      };
    default:
      return state;
  }
};

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [notificationState, dispatch] = useReducer(
    notificationReducer,
    initialState
  );
  const { socket, sources } = useClient();

  useEffect(() => {
    socket.on("disconnect", () => {
      const { message, status } = toastMessages("SERVER_DISCONNECT");
      dispatch({
        type: NotificationDataTypes.SET_TOAST,
        payload: {
          open: true,
          message: message,
          status: status,
        },
      });
    });

    socket.on(
      "TOAST_NOTIFICATION",
      (payload: { code: messageProps; data: { source: string } }) => {
        let fileName = sources.find(
          (file) => file.sourceID === payload.data.source
        )?.name;
        const { message, status } = toastMessages(payload.code, fileName);
        dispatch({
          type: NotificationDataTypes.SET_TOAST,
          payload: {
            open: true,
            message: message,
            status: status,
          },
        });
      }
    );
  }, []);

  return (
    <NotificationCtx.Provider
      value={{
        notificationState,
        dispatch,
      }}
    >
      {children}
    </NotificationCtx.Provider>
  );
};

export const useNotifications = () => {
  return useContext(NotificationCtx) as NotificationContext;
};

export default NotificationProvider;
