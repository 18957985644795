import styled from "styled-components"; // Use this for regular element styling
import { Form } from "formik";

export const EmbeddingStyles = {
  Container: styled.div`
    font-family: "halyard-display";
    height: fit-content;
    max-height: 100%;
    border-radius: 34px;
    width: 485px;
    border: 1px solid #caafbb;
    position: relative;
    background: #ffffff;
    box-shadow: 0 2px 37px 0 rgba(41, 51, 107, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 37px;
    position: relative;
    min-width: 0;


    @media ${props => props.theme.breakpoints.md} {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
    }

    @media (max-width: 525px) {
      padding: 20px;
    }
  `,
  ScriptSection: styled.pre`
    color: black;
    background-color: white;
    padding: 0px 10px 0px 10px;
    border: 1px solid #ece3de;
    opacity: 0.7;
    border-radius: 20.5px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 15px;
    white-space: pre-wrap;
    text-wrap: wrap;
    max-width: 100%;
    max-height: 375px;

    

    @media (min-width: 0px) and (max-width: 670px) {
      width: 99.9%;
    }

    @media (max-width: 505px) {
      font-size: 99%;
    }
    @media (max-width: 435px) {
      font-size: 95%;
    }
  `,
  Title: styled.div`
    color: #20243a;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 10px;
  `,
  SubText: styled.div`
    color: #20243a;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin-bottom: 24px;
    max-width: 364px;
  `,
  ColoredLink: styled.a`
    color: #7689ff;
    font-weight: 500;
    cursor: pointer;
  `,
  TextFieldWrapper: styled.div`
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 7px;
    width: 100%;
  `,

  Terms: styled.p`
    font-size: 12px;
    margin-bottom: 15px;
  `,

  ButtonArea: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    margin-top: 12px;

    @media (max-width: 525px) {
      flex-direction: column;
    }
  `,

  PreviewButton: styled.button`
    color: #7689ff;
    height: 48px;
    width: 228.93px;
    border: 1px solid #7689ff;
    border-radius: 25px;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    column-gap: 10px;
    cursor: pointer;
  `,
  CopyButton: styled.button`
background-color: #7689ff;
    height: 48px;
    width: 152px;
    border: 1px solid #7689ff;
    border-radius: 25px;
    color: #ffffff;
    font-size: 17px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    
    cursor: pointer;

    @media (max-width: 525px) {
      margin-top: 20px;
    }
  `,
  CloseButton: styled.div`
    position: absolute;
    top: 32px;
    right: 48px;
    cursor: pointer;
  `,

  Header: styled.h1`
    height: 41px;
    color: #20243a;
    font-family: "Halyard Display";
    font-size: 30px;
    font-weight: 1;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
    margin-bottom: 20px;
  `,

  PartialBorder: styled.div`
    display: inline;
    height: 1px;
    width: 40%;
    background-color: #e1e0e7;
    margin: 23px 0;
    flex: 1;
  `,

  Form: styled(Form)`
    width: 100%;
    max-width: 500px;
    height: 575px;
    background-color: plum;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,

  TextFieldsContainer: styled.div<{
    mobileLayout?: boolean;
    $isSecondChild?: boolean;
  }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: ${(props) =>
      !props.$isSecondChild ? "0" : props.mobileLayout ? "0" : "20px"};
    margin-bottom: 30px;
    margin-top: ${(props) =>
      props.$isSecondChild && props.mobileLayout ? "16px" : "0"};
  `,
};
