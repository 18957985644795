import { useEffect } from 'react';

const Logout = () => {

    const handleLogout = async () => {
        
        await localStorage.removeItem('JWT_TOKEN');
        await localStorage.removeItem('REFRESH_TOKEN');

        window.location.href = process.env.REACT_APP_REDIRECT + '/logout';
    }

    useEffect(() => {
        const jwtToken = localStorage.getItem('JWT_TOKEN');
        const refreshToken = localStorage.getItem('REFRESH_TOKEN');
        if (!jwtToken && !refreshToken)  window.location.href = process.env.REACT_APP_REDIRECT + '/logout';
        handleLogout();
    }, [])
    

    return(
        <></>
    )
}

export default Logout;