import { useState } from "react";
import LayoutStyles from "../../../App.styles";
import DocCard from "../../Cards/DocCard";
import { SourceType } from "../../../types/AuthTypes";
import { dateToUtcNumber } from "../../../helpers/dateConversion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowUp,
  faUpFromLine,
} from "@fortawesome/pro-solid-svg-icons";
import AddLocalFiles from "../../AddLocalFiles/AddLocalFiles";

const UploadsDataSource = ({
  sources,
  importFiles,
  deleteSource,
}: {
  sources: SourceType[];
  importFiles: () => Promise<void>;
  deleteSource: (sourceID: string) => void;
}) => {
  const { LibrarySection } = LayoutStyles;
  const [uploadOpen, setUploadOpen] = useState<
    "website" | "google-drive" | "one-drive" | "" | "file-upload"
  >("");
  return (
    <LibrarySection.DocumentSection
    style={{
      padding: '15px'
    }}>
      <LibrarySection.TitleSection>
        <LibrarySection.LibraryTitle>
          <FontAwesomeIcon
            icon={faUpFromLine}
            style={{ marginRight: 13, color: "#7689FF" }}
          />
          Uploads
        </LibrarySection.LibraryTitle>
        <LibrarySection.UploadButton
          onClick={() => {
            setUploadOpen("");
            setUploadOpen("file-upload");
          }}
        style={{ display: sources.length === 0 ? 'none' : 'flex'}}

        >
          <FontAwesomeIcon icon={faCircleArrowUp} style={{ height: 18 }} />
          Upload files
        </LibrarySection.UploadButton>
        <LibrarySection.BigUploadButton
          onClick={() => {
            setUploadOpen("");
            setUploadOpen("file-upload");
          }}
          style={{ display: sources.length === 0 ? 'flex' : 'none'}}

        >
          <FontAwesomeIcon icon={faCircleArrowUp} style={{ height: 18 }} />
          Upload files
        </LibrarySection.BigUploadButton>

        <AddLocalFiles
          opened={uploadOpen}
          setOpened={setUploadOpen}
          importFiles={importFiles}
        />
      </LibrarySection.TitleSection>

      <LibrarySection.SubTitle
        style={{ marginBottom: 15, display: sources.length ? "block" : "none" }}
      >
        Recently uploaded files
      </LibrarySection.SubTitle>
      <LibrarySection.DocumentRow container>
        {sources
          .reverse()
          .slice(0, 6)
          .map((source, index) => (
            <DocCard
              name={source.name}
              progress={source.progress}
              uploaded={source.uploaded}
              lastEdited={dateToUtcNumber(source.last_updated)}
              key={source.sourceID}
              fileType={source.fileType}
              source={source}
              deleteSource={deleteSource}
              synced={source.synced}
            />
          ))}
      </LibrarySection.DocumentRow>
      <LibrarySection.DocumentRow
        container
        $margin="0 0 51px 0"
      ></LibrarySection.DocumentRow>
      <LayoutStyles.FlexRow style={{ marginBottom: "15px", display: sources.length === 0 ? 'none' : 'flex', justifyContent: 'flex-start' }}>
        <LibrarySection.SubTitle>All files</LibrarySection.SubTitle>
        <LibrarySection.FileCount>
          {sources.length} files
        </LibrarySection.FileCount>
      </LayoutStyles.FlexRow>
      <LibrarySection.DocumentRow container>
        {sources.map((source, index) => (
          <DocCard
            name={source.name}
            progress={source.progress}
            uploaded={source.uploaded}
            lastEdited={dateToUtcNumber(source.last_updated)}
            key={source.sourceID}
            fileType={source.fileType}
            source={source}
            deleteSource={deleteSource}
            synced={source.synced}
          />
        ))}
      </LibrarySection.DocumentRow>
    </LibrarySection.DocumentSection>
  );
};

export default UploadsDataSource;
