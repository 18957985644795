import LayoutStyles from "../App.styles";
import {
  faBars,
  faPaintRoller,
  faGear,
  faXmark,
  faComment,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { faPenLine } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClient } from "../context/ClientContext";
import { useNotifications } from "../context/NotificationContext";
import { customizeChatbot } from "../actions";
import { ChatbotType } from "../types/AuthTypes";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, List, ListItemText, Tooltip } from "@mui/material";
import { useTheme } from "styled-components";
import { useLibrary } from "../context/LibraryContext";
import ChatBubble from "../assets/icons/chat-bubble.svg";
import LogOutIcon from "../assets/icons/log-out-arrow.svg";
import { useFormik } from "formik";
import { NotificationDataTypes } from "../types/NotificationTypes";

const Header = () => {
  const { Header, DocCard } = LayoutStyles;
  const { currentChatbot, setFeedbackOpen, setCurrentChatbot } = useClient();
  const { dispatch } = useNotifications();
  const { setCustomOpen } = useLibrary();
  const [isMobileDrawerOpen, setMobileDrawer] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);
  const [nameEditing, setNameEditing] = useState(false);
  const nameTextFieldRef = useRef<HTMLInputElement | null>(null);
  const nameAnchor = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleBotNameSubmit = () => {
    const newName = formik.values.assistantName;
    if (newName === "") {
      setNameEditing(false);
      return;
    }
    customizeChatbot({
      assistant_name: newName,
      chatbotID: currentChatbot.chatbotID,
    })
      .then((res: { result: string; data: ChatbotType }) => {
        setNameEditing(false);
        if (res.result === "SUCCESS") {
          dispatch({
            type: NotificationDataTypes.SET_TOAST,
            payload: {
              open: true,
              message: `Your chatbot's name has been changed to ${newName}`,
              status: "success",
            },
          });
          setCurrentChatbot(res.data);
        }
      })
      .catch((err: any) => {
        dispatch({
          type: NotificationDataTypes.SET_TOAST,
          payload: {
            open: true,
            message: `We were not able to update the chatbot's name. Please try again later.`,
            status: "error",
          },
        });
      });
  };

  const initialValues = {
    assistantName: currentChatbot?.assistant_name,
  };

  const formik = useFormik({
    initialValues: {
      assistantName: currentChatbot?.assistant_name
        ? currentChatbot.assistant_name
        : "",
    },
    enableReinitialize: true,
    onSubmit: handleBotNameSubmit,
  });

  const [anchorPosition, setAnchorPosition] = useState<
    | {
        top: number;
        left: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const handleClickOutside = async (event: MouseEvent) => {
      // Check if the clicked element is outside the TextField
      if (
        nameTextFieldRef.current &&
        !nameTextFieldRef.current.contains(event.target as Node)
      ) {
        formik.handleSubmit();
      } else {
        return;
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    navigate("/logout");
  };

  const handleBotNameToggle = () => {
    setNameEditing(true);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl, anchorPosition]);

  useEffect(() => {
    document.addEventListener("click", closeHamburger, true);
    return () => {
      document.removeEventListener("click", closeHamburger, true);
    };
  }, []);

  const closeHamburger = (e: any) => {
    if (!ref.current?.contains(e.target)) {
      setMobileDrawer(false);
    }
  };

  return (
    <Header.HeaderSection ref={nameAnchor}>
      <LayoutStyles.FlexRow style={{ alignItems: "center" }}>
        {currentChatbot?.widget_logo ? (
          <img
            src={currentChatbot?.widget_logo}
            alt=""
            style={{
              height: 45,
              width: 45,
              borderRadius: "50%",
              marginRight: "12px",
            }}
          />
        ) : (
          <Header.BotIcon>
            <span>{currentChatbot?.assistant_name[0]}</span>
          </Header.BotIcon>
        )}
        {!nameEditing && !isSmallScreen && (
          <Tooltip title="Edit assistant name">
            <Header.BotNameButton onClick={handleBotNameToggle}>
              <Header.BotName>{currentChatbot?.assistant_name}</Header.BotName>
            </Header.BotNameButton>
          </Tooltip>
        )}
        {nameEditing && !isSmallScreen && (
          <LayoutStyles.TextField
            ref={nameTextFieldRef}
            autoFocus
            id="assistantName"
            name="assistantName"
            value={formik.values.assistantName}
            onChange={formik.handleChange}
            inputProps={{
              maxLength: 30,
            }}
            InputProps={{
              endAdornment: (
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={formik.handleReset}
                  icon={faXmark}
                  size="lg"
                />
              ),
            }}
          />
        )}
        {isSmallScreen && (
          <div
            onClick={() => setPopoverOpen(true)}
            style={{
              display: "flex",
              alignItems: "center",
              width: "255px",
            }}
          >
            <Header.BotName>{currentChatbot?.assistant_name}</Header.BotName>
            <FontAwesomeIcon
              icon={faPenLine}
              size="xs"
            />
          </div>
        )}
      </LayoutStyles.FlexRow>
      {!isSmallScreen && (
        <LayoutStyles.FlexRow>
          <LayoutStyles.LibraryPage.SubLink
            onClick={() =>
              window.open(window.location.origin + "/preview", "_blank")
            }
            style={{ minWidth: 100 }}
          >
            Install Assistant
          </LayoutStyles.LibraryPage.SubLink>

          <LayoutStyles.LibraryPage.SubLink onClick={() => setCustomOpen(true)}>
            Customize
          </LayoutStyles.LibraryPage.SubLink>

          <DocCard.Wrapper
            onClick={handleClick}
            style={{
              cursor: "pointer",
            }}
          >
            Settings
          </DocCard.Wrapper>
          <DocCard.Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            autoFocus={false}
            style={{
              margin: "10px 0",
              left: "-70px",
              width: "25vw",
              padding: "10px",
            }}
          >
            <DocCard.MenuItem
              onClick={() => setFeedbackOpen(true)}
              style={{
                width: "95%",
                height: "50%",
                padding: "10px",
                margin: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#7689FF",
                  height: "20px",
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={ChatBubble}
                  alt="chat-bubble-icon"
                  style={{
                    height: "10px",
                    width: "10px",
                  }}
                />
              </div>
              Share your feedback
            </DocCard.MenuItem>
            <DocCard.MenuItem
              onClick={handleLogout}
              style={{
                width: "95%",
                height: "50%",
                padding: "10px",
                margin: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#7689FF",
                  height: "20px",
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={LogOutIcon}
                  alt="log-out-icon"
                  style={{
                    height: "10px",
                    width: "10px",
                    color: "white",
                  }}
                />
              </div>
              Log Out
            </DocCard.MenuItem>
          </DocCard.Menu>
        </LayoutStyles.FlexRow>
      )}
      {isSmallScreen && (
        <FontAwesomeIcon
          onClick={() => setMobileDrawer(true)}
          icon={faBars}
          size="xl"
        />
      )}

      <Header.MobileDrawer
        open={isMobileDrawerOpen}
        anchor="right"
        ref={ref}
      >
        <LayoutStyles.FlexColumn>
          <LayoutStyles.FlexRow
            onClick={() => setMobileDrawer(false)}
          ></LayoutStyles.FlexRow>
          <LayoutStyles.FlexRow
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#EFDCDA",
              width: "100%",
              padding: "19px 30px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {currentChatbot?.widget_logo ? (
                <img
                  src={currentChatbot?.widget_logo}
                  alt=""
                  style={{
                    height: 31,
                    width: 31,
                    borderRadius: "50%",
                    marginRight: "12px",
                  }}
                />
              ) : (
                <Header.BotIcon>
                  <span>{currentChatbot?.assistant_name[0]}</span>
                </Header.BotIcon>
              )}
              <Header.BotName>{currentChatbot?.assistant_name}</Header.BotName>
            </div>

            <FontAwesomeIcon
              icon={faXmark}
              size="xl"
              onClick={() => {
                setMobileDrawer(false);
              }}
            />
          </LayoutStyles.FlexRow>
          <LayoutStyles.FlexRow>
            <List
              style={{
                padding: "10px",
              }}
            >
              <Header.MobileDrawerItem onClick={() => setCustomOpen(true)}>
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon
                    icon={faPaintRoller}
                    inverse
                    size="1x"
                  />
                </Header.MobileDrawerIcon>
                <ListItemText>Customize</ListItemText>
              </Header.MobileDrawerItem>
              <Header.MobileDrawerItem
                onClick={() =>
                  window.open(window.location.origin + "/preview", "_blank")
                }
              >
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon
                    icon={faGear}
                    inverse
                    size="1x"
                  />
                </Header.MobileDrawerIcon>
                <ListItemText>Install Assistant</ListItemText>
              </Header.MobileDrawerItem>
              <Header.MobileDrawerItem onClick={() => setFeedbackOpen(true)}>
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon
                    icon={faComment}
                    inverse
                    size="1x"
                  />
                </Header.MobileDrawerIcon>
                <ListItemText>Share your feedback</ListItemText>
              </Header.MobileDrawerItem>
              <Header.MobileDrawerItem onClick={handleLogout}>
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    inverse
                    size="1x"
                  />
                </Header.MobileDrawerIcon>
                <ListItemText>Log out</ListItemText>
              </Header.MobileDrawerItem>
            </List>
          </LayoutStyles.FlexRow>
        </LayoutStyles.FlexColumn>
      </Header.MobileDrawer>
      <Header.BotNamePopover
        anchorEl={nameAnchor.current}
        open={popoverOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <LayoutStyles.FlexRow
          style={{ justifyContent: "space-between", marginBottom: "16px" }}
        >
          <p>Edit Name</p>
          <FontAwesomeIcon
            size="xl"
            onClick={() => setPopoverOpen(false)}
            icon={faXmark}
          />
        </LayoutStyles.FlexRow>
        <LayoutStyles.TextField
          sx={{
            "&&": {
              width: "100%",
              marginBottom: "24px",
            },
          }}
          id="assistantName"
          name="assistantName"
          value={formik.values.assistantName}
          onChange={formik.handleChange}
        />
        <LayoutStyles.FlexRow
          style={{ justifyContent: "space-between", marginBottom: "16px" }}
        >
          <Header.PopoverButton
            onClick={() => setPopoverOpen(false)}
            $bgColor="white"
            $color={theme.colors.primary.main}
          >
            Cancel
          </Header.PopoverButton>
          <Header.PopoverButton
            onClick={() => {
              handleBotNameSubmit();
              setPopoverOpen(false);
            }}
            $bgColor={theme.colors.primary.main}
            $color="white"
          >
            Save
          </Header.PopoverButton>
        </LayoutStyles.FlexRow>
      </Header.BotNamePopover>
    </Header.HeaderSection>
  );
};

export default Header;
