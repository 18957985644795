export const dateToUtcNumber = (dateString: string) => {
  const utcNumber = Date.parse(dateString);
  return utcNumber;
};

export const generateTempID = () => {
  let result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 7; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
