import { useEffect, useState } from "react";
import PreviewStyles from "./PreviewStyling";
import { useClient } from "../../context/ClientContext";
import PreviewIframe from "../../assets/images/preview_iframe.png";
import ChatbotScript from "../../components/ChatbotScript/ChatbotScript";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import FooterSection from "../../components/Footer";

const PreviewPage = () => {
  const { currentChatbot } = useClient();
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.md);
  const useVeriticalLayout = useMediaQuery('(max-width: 1245px)')
  const [script1, setScript1] = useState<HTMLScriptElement>();
  const [script2, setScript2] = useState<HTMLScriptElement>();

  useEffect(() => {
    if (!currentChatbot) return;
    const iframe = document.querySelector("#myIframe") as HTMLIFrameElement;

    if (iframe) {
      console.log("iframe", iframe);
      const builtScript1 = document.createElement("script");
      builtScript1.src = "https://bot.dev.infobox.ai/embed/infobox-ai-bot.js";
      setScript1(builtScript1);

      const builtScript2 = document.createElement("script");
      builtScript2.textContent = `
        <script src=
        "${process.env.REACT_APP_BOT_URL}embed/infobox-ai-bot.js"
        ></script>
        <script>
          setTimeout(() => {
              xurealAIButton.init(
               "${process.env.REACT_APP_BOT_URL}ai-chatbot/" +
               "?client=${currentChatbot?.chatbotID}&share=true",
                  {
                    right: "0px",
                   bottom: "0px",
                 }
              );
         }, 1e3);
        </script>
        `;
      setScript2(builtScript2);
    }
  }, [currentChatbot]);

  useEffect(() => {
    console.log("script 1 running");
    const iframe = document.getElementById("#myIframe") as HTMLIFrameElement;
    if (!script1) return;

    iframe?.contentDocument?.head.appendChild(script1);
  }, [script1]);

  useEffect(() => {
    const iframe = document.getElementById("#myIframe") as HTMLIFrameElement;
    if (!script2) return;
    console.log("script 2 running", script2);
    iframe?.contentDocument?.head.appendChild(script2);
  }, [script2]);

  return (
    <>
      <PreviewStyles.PageContainer>
        {!isSmallScreen && (
          <>
            <div style={{flex: 1, width: '100%', maxWidth: '1192.05px',}}>
          <PreviewStyles.Iframe
            srcDoc={`<html><head><script src="${process.env.REACT_APP_BOT_URL}embed/infobox-ai-bot.js"
          ></script>
          <script>
            setTimeout(() => {
                xurealAIButton.init(
                 "${process.env.REACT_APP_BOT_URL}ai-chatbot/" +
                 "?client=${currentChatbot?.chatbotID}",
                    {
                      right: "0px",
                     bottom: "0px",
                   }
                );
           }, 1e3);
          </script></head><body><img src="${PreviewIframe}" style="height: ${useVeriticalLayout ? "800px" : "100vh"}; width: "100%" /></body></html>`}
            scrolling="no"
            src={PreviewIframe}
          />
          <p style={{ textAlign: 'right', fontSize: '12px', paddingBottom: '10px'}}>Preview your AI Assistant by clicking on the icon above</p>
          </div>
        </>
        )}
        {currentChatbot && <ChatbotScript />}
      </PreviewStyles.PageContainer>
      <FooterSection />
    </>
  );
};

export default PreviewPage;
