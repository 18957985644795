import { useEffect } from "react";
import { useLibrary, Integrations } from "../../context/LibraryContext";
import LayoutStyles from "../../App.styles";
import {
  getSourceByID,
  getSourcesByChatbot,
  removeChatbotSource,
} from "../../actions";
import { useClient } from "../../context/ClientContext";
import { SourceType } from "../../types/AuthTypes";
import GoogleDataSource from "./Views/GoogleDataSource";
import WebsiteDataSource from "./Views/WebsiteDataSource";
import UploadsDataSource from "./Views/UploadsDataSource";
import { useNotifications } from "../../context/NotificationContext";
import { toastMessages } from "../../utils/toastMessages";
import { NotificationDataTypes } from "../../types/NotificationTypes";
import AllSourcesAccordion from "./Views/AllSourcesAccordion";

const LibraryBody = () => {
  const { activeLibPage } = useLibrary();
  const {
    currentChatbot,
    setLoading,
    AddSourcesToBot,
    uploadStatus,
    sources,
    setSources,
  } = useClient();
  const { dispatch } = useNotifications();
  const { LibrarySection } = LayoutStyles;

  useEffect(() => {
    if (uploadStatus)
      setSources((prev) => {
        let idx = prev.findIndex(
          (source) => source.sourceID === uploadStatus.sourceID
        );
        if (idx >= 0) {
          prev[idx].upload_status = uploadStatus.upload_status;
          if (uploadStatus.upload_status.type === "completed") {
            prev[idx].synced = true;
            prev[idx].last_updated = new Date().toString();
          }
        }

        return [...prev];
      });
  }, [uploadStatus]);

  useEffect(() => {
    if (currentChatbot) {
      setLoading(true);

      getSourcesByChatbot({ chatbotID: currentChatbot.chatbotID }).then(
        (res: { result: string; data: SourceType[] }) => {
          if (res.result === "SUCCESS") {
            setSources(res.data);
          }
          setLoading(false);
        }
      );
    }
  }, [currentChatbot, setLoading]);

  const importFiles = async () => {
    try {
      await AddSourcesToBot().then(
        (res: {
          result: string;
          data: string[];
          deleted_sources: string[];
        }) => {
          if (res.result === "SUCCESS") {
            setSources((prev) =>
              prev.filter((el) => !res.deleted_sources.includes(el.sourceID))
            );
            for (let src of res.data) {
              getSourceByID({
                sourceID: src,
                chatbotID: currentChatbot.chatbotID,
              })
                .then((res: { result: string; data: SourceType }) => {
                  setSources((prev) => [...prev, res.data]);
                })
                .catch((err: any) => console.log(err));
            }
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const deleteSource = (sourceID: string) => {
    removeChatbotSource({ chatbotID: currentChatbot.chatbotID, sourceID }).then(
      (res: { result: string }) => {
        if (res.result === "SUCCESS") {
          setSources((prev) => prev.filter((el) => el.sourceID !== sourceID));
          const { message, status } = toastMessages('FILE_DELETED')
          dispatch({
            type: NotificationDataTypes.SET_TOAST,
            payload: {
              open: true,
              message: message,
              status: status
            }
            
          })
        }
      }
    );
  };

  return (
    <LibrarySection.Container>
      {activeLibPage === Integrations.All && (
        <AllSourcesAccordion
          sources={sources}
          importFiles={importFiles}
          deleteSource={deleteSource}
        />
      )}
      {/* {activeLibPage === Integrations.OneDrive && (
        <OneDataSource
          sources={sources.filter(
            (source) => source.sourceType === "one-drive"
          )}
        />
      )} */}
      {activeLibPage === Integrations.Google && (
        <GoogleDataSource
          sources={sources.filter(
            (source) => source.sourceType === "google-drive"
          )}
          importFiles={importFiles}
          deleteSource={deleteSource}
        />
      )}
      {activeLibPage === Integrations.Website && (
        <WebsiteDataSource
          sources={sources.filter((source) => source.sourceType === "website")}
          importFiles={importFiles}
          deleteSource={deleteSource}
        />
      )}
      {activeLibPage === Integrations.Uploads && (
        <UploadsDataSource
          sources={sources.filter(
            (source) => source.sourceType === "file-upload"
          )}
          importFiles={importFiles}
          deleteSource={deleteSource}
        />
      )}
    </LibrarySection.Container>
  );
};

export default LibraryBody;
