import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import {
  Button,
  TextField,
  Grid,
  Dialog,
  MenuItem,
  Menu,
  Select,
  Drawer,
  ListItem,
  Tooltip,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalBg from "./assets/images/modal-bottom.png";
import CreateBackground from "./assets/images/create-bg.png";
import HomeBackground from "./assets/images/homepage/home-bg.png";

type FlexBoxAtttributes = {
  $justifyContent?: string;
  $alignItems?: string;
  $height?: string;
  $width?: string;
  $padding?: string;
  $margin?: string;
  $flexWrap?: string;
  $flex?: string;
  $flexDirection?: string;
};

const LayoutStyles = {
  Container: styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    row-gap: 40px;
    background-image: url(${HomeBackground});
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  `,
  HomeHeader: styled.header`
    width: 100%;
    height: 125px;
    display: flex;
    padding: 0 3vw;
    align-items: center;
    justify-content: space-between;
  `,
  SearchBar: styled(TextField)`
    && {
      height: 37px;
      border: 1px solid #f1e6e0;
      border-radius: 20.5px;
      width: 312px;
      max-width: 40%;
      & .MuiInputBase-root {
        height: 100%;
        margin-bottom: 0;
        border-radius: 41px;
        border: none;
      }
      & .MuiOutlinedInput-input {
        height: 100%;
        border: none;
        font-size: 16px;
        color: ${(props) => props.theme.colors.neutral.dark};
        padding-left: 9px;
        font-weight: 300;
      }
      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  `,
  SearchIcon: styled(FontAwesomeIcon)`
    height: 17px;
  `,
  FlexColumn: styled.div<FlexBoxAtttributes>`
    display: flex;
    flex-direction: column;
    margin: ${(props) => (props.$margin ? props.$margin : "0")};
    padding: ${(props) => (props.$padding ? props.$padding : "0")};
    width: ${(props) => (props.$width ? props.$width : "auto")};
    height: ${(props) => (props.$height ? props.$height : "auto")};
    flex: ${(props) => (props.$flex ? props.$flex : "unset")};
    justify-content: ${(props) =>
      props.$justifyContent ? props.$justifyContent : "center"};
    align-items: ${(props) =>
      props.$alignItems ? props.$alignItems : "center"};
  `,
  FlexRow: styled.div<FlexBoxAtttributes>`
    display: flex;
    align-items: center;
    margin: ${(props) => (props.$margin ? props.$margin : "0")};
    padding: ${(props) => (props.$padding ? props.$padding : "0")};
    width: ${(props) => (props.$width ? props.$width : "auto")};
    height: ${(props) => (props.$height ? props.$height : "auto")};
    flex: ${(props) => (props.$flex ? props.$flex : "unset")};
    flex-direction: ${(props) =>
      props.$flexDirection ? props.$flexDirection : "unset"};
    justify-content: ${(props) =>
      props.$justifyContent ? props.$justifyContent : "center"};
    align-items: ${(props) =>
      props.$alignItems ? props.$alignItems : "center"};
  `,

  HomeTitle: styled.h1`
    font-size: clamp(42px, 5.6vw, 80px);
    font-weight: 500;
    margin-bottom: 26px;
  `,
  HomeSubtitle: styled.h3`
    font-size: clamp(16px, 1.55vw, 22px);
    text-align: center;
    margin-bottom: 30px;
    height: 50px;
    color: #3f3f3f;
    font-weight: 300;
  `,
  ImageContainer: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1440px;
  `,
  HomeLogo: styled.img`
    height: 37px;
  `,
  HomeFooter: styled.section`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
  `,
  SignupButton: styled.button`
    font-size: 18px;
    color: white;
    line-height: 29px;
    padding: 10px 22px;
    border: none;
    cursor: pointer;
    border-radius: 24px;
    box-shadow: 0 0 14px 0 rgba(49, 68, 181, 0.55);
    background: #7689ff;
  `,
  AILogoContainer: styled.img`
    height: 71px;
    width: 71px;
    border-radius: 50%;
    position: fixed;
    bottom: 3vw;
    right: 3vw;
  `,
  LegalFooter: styled.p`
    font-size: 12px;
    color: #372e2e;
    font-weight: 300;
  `,
  SlideContainer: styled.div`
    width: 70%;
    max-width: 690px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 20px;
    background-color: white;
    width: 540px;
    height: 600px;
    border-radius: 30px;
    padding: 50px;
    box-sizing: border-box;
  `,
  LargeTextField: styled(TextField)`
    && {
      height: 62px;
      border-radius: 41px;
      border: 1px solid ${(props) => props.theme.colors.primary.main};
      background-color: #fff;
      width: 100%;
      max-width: 518px;
      overflow: hidden;
      margin: 0 auto;
      & .MuiInputBase-root {
        height: 100%;
        margin-bottom: 0;
        border-radius: 41px;
        border: none;
        padding-right: 9px;
        & input:-webkit-autofill {
          -webkit-text-fill-color: inherit !important;
          -webkit-box-shadow: 0 0 0 1000px white inset !important;
        }
      }
      & .MuiOutlinedInput-input {
        text-overflow: ellipsis;
        height: 100%;
        border: none;
        font-size: 18px;
        padding-left: 32px;
        flex: 1;
      }
      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 48px;
        font-size: 16px;
        & .MuiOutlinedInput-input {
          font-size: 14px;
        }
      }
    }
  `,
  LargeTextFieldButton: styled(Button)<{ $enabled?: boolean }>`
    && {
      height: 46px;
      width: 71px;
      border-radius: 31px;
      background-color: ${(props) => props.theme.colors.primary.main};
      box-shadow: 0 2px 7px 0 rgba(117, 114, 109, 0.13);
      background-color: ${(props) =>
        props.$enabled ? props.theme.colors.primary.main : "#E7E2DF"};
      &:hover {
        background-color: ${(props) =>
          props.$enabled ? props.theme.colors.primary.main : "#E7E2DF"};
      }
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 36px;
        width: 55px;
      }
    }
  `,
  TextFieldError: styled.div`
    font-size: 12px;
    color: red;
    text-align: left;
    width: 100%;
    padding-left: 32px;
  `,
  HeaderButton: styled.button<{ $bgColor?: string }>`
    height: 38px;
    width: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 19px;
    color: ${(props) => props.theme.colors.primary.main};
    font-weight: 400;
    background: ${(props) => (props.$bgColor ? props.$bgColor : "transparent")};
    border: none;
    cursor: pointer;
  `,
  Highlight: styled.span`
    color: ${(props) => props.theme.colors.primary.main};
  `,
  TextField: styled(TextField)`
    && {
      height: 46px;
      width: 250px;
      background-color: white;
      font-size: 30px;
      border-radius: 41px;
      & .MuiInputBase-root {
        height: 46px;
        border-radius: 41px;
      }
      & .MuiOutlinedInput-input {
        text-overflow: ellipsis;
      }
    }
  `,

  Button: styled(Button)`
    && {
      max-width: 500px;
      width: 100%;
      margin-bottom: 35px;
    }
  `,
  UrlHeader: styled.h3`
    ${(props) => props.theme.typography.h3};
    font-weight: 400;
  `,
  Favicon: styled.img`
    height: 32px;
    width: 32px;
    margin-right: 9px;
  `,
  WebpageCount: styled.p`
    font-size: 12px;
    line-height: 17px;
  `,
  UrlMenuContainer: styled.div`
    max-height: 600px;
    width: fit-content;
    overflow-y: auto;
    margin-bottom: 46px;

    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  `,
  WebsiteContainer: styled.div`
    max-height: 450px;
    overflow: scroll;
    margin: 0 auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    ::-webkit-scrollbar-track {
      display: none;
    }
  `,
  UrlBox: styled.div`
    min-height: 48px;
    width: 332px;
    word-wrap: break-word;
    display: flex;
    align-items: flex-start;
    column-gap: 30px;
    box-sizing: border-box;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    color: ${(props) => props.theme.colors.neutral.light};
    cursor: pointer !important;
    & label {
      cursor: pointer;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.creme};
    }
  `,

  UrlCheckBox: styled.input.attrs({ type: "checkbox" })`
    display: none;
    padding-top: 5px;
  `,
  UrlCirceCheckBox: styled.label<{ $checked: boolean }>`
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-top: 5px;
    border-radius: 50%;
    border: 1px solid #d6c3c3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.$checked ? props.theme.colors.primary.main : "transparent"};
    cursor: pointer;
  `,
  Table: styled.table`
    border: 1px solid black;
    min-width: 700px;
    max-width: 1000px;
    width: 80vw;
    border: 1px solid black;
    border-collapse: collapse;
  `,
  TableCell: styled.td`
    max-width: 350px !important;
    width: fit-content;
    height: fit-content;
    min-height: 45px !important;
    box-sizing: border-box;
    padding: 7px 15px;
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 16px;
    font-family: "halyard-display";
    line-height: 21px;
  `,
  TableRow: styled.tr`
    min-height: 45px !important;
    display: table-row;
    border: 1px solid black;
    border-collapse: collapse;
  `,
  TextArea: styled(TextareaAutosize)`
    width: 100%;
    height: fit-content;
    outline: none;
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    resize: none;
    padding: 0px;
    font-size: 16px;
    font-family: "halyard-display";
    overflow: hidden;
    line-height: 21px;
    touch-action: manipulation;
    box-sizing: border-box;
  `,
  // Progress bar styling
  ProgressContainer: styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;

    margin-bottom: 50px;
    background-color: white;
    width: 540px;
    height: 600px;
    border-radius: 30px;
    padding: 30px;
  `,
  ProgressUrl: styled(motion.p)`
    font-size: 14px;
    font-weight: 600;
    width: 240px;
    text-align: center;
    font-family: "halyard-display";
    color: #3f3f3f;
  `,
  ProgressTrack: styled(motion.div)`
    height: 9px;
    width: 230px;
    border-radius: 12px;
    background-color: #e1e1e1;
    position: relative;
    overflow: hidden;
  `,
  ProgressBar: styled(motion.div)`
    height: 9px;
    width: 230px;
    border-radius: 12px;
    background-color: #77e971;
    position: absolute;
  `,
  GenerateModal: styled.dialog`
    width: 690px;
    height: 500px;
    border: none;
    border-radius: 30px;
    z-index: 999;
    padding: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Homepage: styled.div``,
  Header: {
    HeaderSection: styled.header`
      width: 100%;
      padding: 30px 102px;
      display: flex;
      align-items: center;
      background-color: ${(props) => props.theme.colors.beige};
      justify-content: space-between;
      height: 145px;
      @media ${(props) => props.theme.breakpoints.md} {
        padding: 25px 50px;
      }
      @media ${(props) => props.theme.breakpoints.sm} {
        padding: 19px 28px;
        height: fit-content;
        position: sticky;
      }
    `,
    BotIcon: styled.div`
      height: 45px;
      width: 45px;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      color: white;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 12px;
      background: linear-gradient(
        180deg,
        ${(props) => props.theme.colors.cornflowerBlue} 0%,
        ${(props) => props.theme.colors.hotMagenta} 100%
      );
    `,
    BotNameButton: styled.button`
      text-decoration: none;
      border: none;
      box-shadow: none;
      cursor: pointer;
      background: transparent;
    `,
    BotName: styled.h2`
      ${(props) => props.theme.typography.h2}
      font-family: "halyard-display";
      color: #000;
      @media ${(props) => props.theme.breakpoints.sm} {
        margin-right: 10px;
        font-size: 19px;
        font-weight: 300;
      }
    `,
    BotNamePopover: styled(Popover)`
      & .MuiPaper-root {
        width: 90vw;
        max-width: 342px;
        border-radius: 15px;
        padding: 10px 18px;
        p {
          font-size: 22px;
          font-weight: 400;
        }
      }
    `,
    PopoverButton: styled.button<{ $bgColor: string; $color: string }>`
      font-size: 15px;
      box-shadow: none;
      width: 133px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      background-color: ${(props) => props.$bgColor};
      color: ${(props) => props.$color};
      border: ${(props) =>
        props.$bgColor === "white"
          ? `1px solid ${props.theme.colors.primary.main}`
          : "none"};
      border-radius: 43.5px;
    `,
    TestButton: styled.button<{ $disabled: boolean }>`
      font-size: 18px;
      color: white;
      line-height: 29px;
      padding: 10px 22px;
      border: none;
      cursor: ${(props) => (props.$disabled ? "default" : "pointer")};
      border-radius: 24px;
      box-shadow: ${(props) =>
        props.$disabled ? "none" : "0 0 14px 0 rgba(49, 68, 181, 0.55)"};
      background: linear-gradient(
        184.81deg,
        ${(props) =>
            props.$disabled
              ? props.theme.colors.neutral.light
              : props.theme.colors.blueViolet}
          0%,
        ${(props) =>
            props.$disabled ? "gray" : props.theme.colors.cornflowerBlue}
          100%
      );
      @media ${(props) => props.theme.breakpoints.md} {
        font-size: 16px;
        padding: 8px 20px;
      }
      @media ${(props) => props.theme.breakpoints.sm} {
        font-size: 14px;
        content: "Test";
        padding: 8px 0;
        width: 85%;
        margin: 0 auto;
        height: 60px;
      }
    `,
    SubLink: styled.button`
      ${(props) => props.theme.typography.link}
      margin: 0 50px 0 0;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
    `,
    MobileDrawer: styled(Drawer)`
      pointer-events: none;
      & .MuiPaper-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        pointer-events: auto;
        background-color: white;
      }
    `,
    StyledTooltip: styled(Tooltip)`
      & .MuiTooltip-tooltip {
        color: red;
      }
    `,
    MobileDrawerItem: styled(ListItem)`
      && {
        width: 100%;
        font-weight: thin;
        background-color: ${(props) => props.theme.colors.main};
        & .MuiTypography-root {
          font-size: 22px;
          font-family: "halyard-display";
          margin: 13px;
        }
      }
    `,
    MobileDrawerIcon: styled.div<any>`
      background-color: #7689ff;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      margin-right: 15px;
      margin-left: 10px;
    `,
  },
  Footer: {
    Container: styled.div`
      height: 62px;
      width: 100%;
      background-color: #ffffff;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      padding: 0 37px;
      bottom: 0;
      z-index: 5;
      @media ${(props) => props.theme.breakpoints.sm} {
        position: relative;
      }
    `,
    BetaLogo: styled.img`
      height: 38px;
      width: 126.18px;
      @media ${(props) => props.theme.breakpoints.sm} {
        height: 28px;
        width: 95.02px;
      }
    `,
    XurealLogo: styled.img`
      height: 48px;
      width: 144.02px;
      @media ${(props) => props.theme.breakpoints.sm} {
        transform: scale(0.8);
      }
    `,
  },
  LibraryPage: {
    Body: styled.div`
      min-height: 100%;
      height: fit-content;
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 1284px) and (min-width: 769px) {
        padding-bottom: 100px;
      }
    `,
    SubMenu: styled.nav`
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 52px;
      width: 100%;
      padding: 0 102px;
      border-bottom: 1px solid #f1e6e0;
      background-color: white;
      z-index: 199;
      @media ${(props) => props.theme.breakpoints.md} {
        padding: 0 50px;
      }
      @media ${(props) => props.theme.breakpoints.sm} {
        padding: 0 28px;
        position: sticky;
        top: 0px;
      }
    `,
    SourceSelect: styled(Select).attrs({ variant: "standard" })`
      height: 100%;
      && {
        font-family: "halyard-display";
        padding: 5px;
      }
      & .MuiSelect-select:focus {
        background-color: transparent;
      }
    `,
    SourceMenuItem: styled(MenuItem)`
      font-size: 16px;
      font-weight: 400;
      && {
        font-family: "halyard-display";
      }
    `,
    SubIcon: styled.img`
      margin-right: 11px;
      height: 20px;
    `,
    SubTitle: styled.h4`
      ${(props) => props.theme.typography.h4}
      margin: 0 54px 0 0;
      @media ${(props) => props.theme.breakpoints.md} {
        margin-right: 33px;
        font-size: 16px;
      }
    `,
    SubLink: styled(motion.button)<{ $active?: boolean }>`
      ${(props) => props.theme.typography.link}
      margin: 0 50px 0 0;
      color: #000;
      height: 100%;
      min-height: 52px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      text-wrap: nowrap;
      @media ${(props) => props.theme.breakpoints.md} {
        font-size: 14px;
        margin-right: 25px;
      }
    `,
    BottomSelector: styled(motion.div)`
      height: 2px;
      left: 0;
      right: 0;
      border-radius: 3px;
      background-color: #7689ff;
      position: absolute;
      bottom: 0;
    `,
    LogoutButton: styled(Button)`
      box-shadow: none;
      border: none;
      margin-left: 50px;
      color: #000 !important;
      width: fit-content;
      &.MuiButton-root {
        &:hover {
          background-color: transparent;
        }
      }
    `,
    ChatbotButton: styled(motion.div)<{ $bgImage: string }>`
      height: 100px;
      width: 100px;
      position: fixed;
      bottom: 70px;
      right: 20px;
      background-image: url(${(props) => props.$bgImage});
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 6;
      cursor: pointer;
      user-select: none;
      @media ((max-width: 768px)) {
        transform: scale(0.95) !important;
      }
      @media ${(props) => props.theme.breakpoints.sm} {
        height: 90px;
        width: 90px;
        bottom: 55px;
        right: 15px;
      }
    `,
  },
  DocCard: {
    Box: styled(motion.div)<{
      $synced?: boolean;
      $uploaded?: boolean;
      $maxWidth?: string;
    }>`
      width: 100%;
      max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : "200px")};
      border: 1px solid #efe8e2;
      border-radius: 14px;
      display: flex;
      position: relative;
      flex-direction: column;
      padding: ${(props) => (props.$synced ? "17px 0" : "0 0 9px")};
      user-select: none;
      background-color: white;
      opacity: ${(prop) => (prop.$uploaded ? "1" : "0.7")};
      overflow: hidden;
      @media ${(props) => props.theme.breakpoints.sm} {
        max-width: unset;
      }
    `,
    ProgressBar: styled(motion.div)`
      height: 3px;
      width: 100%;
      border-radius: 2px;
      background-color: #23cd8f;
    `,
    ProgressContainer: styled(motion.div)`
      height: 3px;
      width: 163px;
      border-radius: 2px;
      background-color: #e9e6e6;
      overflow: hidden;
      margin: 0 auto;
      position: absolute;
      bottom: 8px;
      left: 16px;
    `,
    UploadCompleteCheck: styled(motion.div)`
      position: absolute;
      top: 8px;
      right: 8px;
    `,
    Wrapper: styled.div`
      display: flex;
      width: 100%;
      padding: 0 11px;
    `,
    Icon: styled.img`
      height: 100%;
      margin-right: 12px;
    `,
    NewVersionBar: styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: ${(props) => props.theme.colors.primary.main};
      background-color: ${(props) => props.theme.colors.primary.extraLight};
      padding: 0 11px;
      width: 100%;
      height: 22.19px;
      margin-bottom: 4.5px;
    `,
    Name: styled.p`
      font-size: 14px;
      width: 110px;
      font-weight: 500;
      text-overflow: ellipsis;
      max-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      color: ${(props) => props.theme.colors.neutral.dark};
    `,
    LastUpdated: styled.p`
      font-size: 12px;
      color: ${(props) => props.theme.colors.neutral.dark};
      opacity: 0.5;
    `,
    MenuItem: styled(MenuItem)`
      && {
        font-size: 14px;
        color: black;
        width: 220px;
        height: 57px;
        display: flex;
        align-items: center;
        padding: 5px 13px;
        column-gap: 13px;
        &:hover {
          background-color: #f1f3ff;
        }
        border-radius: 13px;
      }
    `,
    MenuTextTitle: styled.div`
      font-size: 13px;
      color: black;
      margin-bottom: -3px;
    `,
    MenuTextSubTitle: styled.div`
      font-size: 11px;
      color: #989aab;
    `,
    MenuIcon: styled.div`
      font-size: 12px;
      background-color: #7688ff;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    `,
    Menu: styled(Menu)`
      & .MuiMenu-paper {
        border-radius: 15px !important;
      }
      & .MuiMenuItem-root {
        font-size: 12px;
      }
    `,
  },

  LibrarySection: {
    Container: styled.section`
      width: 100%;
      padding: 30px 102px 75px;
      flex: 1;
      @media ${(props) => props.theme.breakpoints.md} {
        padding: 25px 50px 0;
      }
      @media ${(props) => props.theme.breakpoints.sm} {
        padding: 0;
      }
    `,
    Footer: styled.footer`
      width: 100%;
      height: 62px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
      background-color: #fff;
    `,
    LibraryTitle: styled.h3`
      ${(props) => props.theme.typography.h3}
      font-weight: 300;
      display: flex;
      align-items: center;
      width: fit-content;
      padding-right: 12.5px;
      height: 25px;
      border-right: 1px solid #f1e6e0;
      @media ${(props) => props.theme.breakpoints.sm} {
        font-size: 20px;
      }
    `,
    TitleSection: styled.div`
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      @media ${(props) => props.theme.breakpoints.sm} {
        justify-content: flex-start;
        margin-top: 25px;
      }
    `,
    UploadButton: styled.div`
      padding: 0 13px;
      display: flex;
      column-gap: 9px;
      align-items: center;
      color: #7689ff;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      cursor: pointer;
    `,
    BigUploadButton: styled.div`
      cursor: pointer;
      padding: 0 13px;
      display: flex;
      column-gap: 9px;
      align-items: center;
      justify-content: center;
      background-color: #7689ff;
      color: white;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 29px;
      border-radius: 20px;
      position: absolute;
      margin: auto;
      width: 20%;
      min-width: 245px;
      height: 5%;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
    IntegrationIcon: styled.img`
      height: 23px;
      margin-right: 13px;
    `,
    Divider: styled.div`
      box-sizing: border-box;
      height: 25px;
      width: 1px;
      border: 1px solid #f1e6e0;
    `,
    SubTitle: styled.p`
      color: #9499b4;
      font-size: 16px;
      margin-right: 8px;
    `,
    FileCount: styled.p`
      font-size: 12px;
      font-weight: 300;
      padding: 8px;
      color: #9499b4;
      border-left: 1px solid #f1e6e0;
    `,
    DocumentSection: styled.div`
      padding: 45px 0;
      border-bottom: 1px solid #f1e6e0;
      @media ${(props) => props.theme.breakpoints.sm} {
        width: 100%;
        border: none;
        padding: 0;
      }
    `,
    DocumentRow: styled(Grid)<{ $margin?: string }>`
      row-gap: 30px;
      column-gap: 20px;
      margin: ${(props) => (props.$margin ? props.$margin : "0")};
    `,
  },
  CreatePage: {
    BackButton: styled.button`
      border: 1px solid ${(props) => props.theme.colors.primary.main};
      color: ${(props) => props.theme.colors.primary.main};
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 16px;
      box-shadow: none;
      background-color: transparent;
      display: flex;
      padding: 10px 20px;
      border-radius: 18.5px;
      align-items: center;
      cursor: pointer;

      @media ${(props) => props.theme.breakpoints.sm} {
        padding: 7px 15px;
        font-size: 14px;
        left: 15px;
        font-size: 12px;
        top: 20px;
      }
    `,
    Container: styled.div`
      height: 100%;
      width: 100%;
      display: flex;
      @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: column;
      }
    `,
    DeleteButton: styled.div`
      height: 24px;
      width: 24px;
      position: absolute;
      top: -8px;
      right: -5px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #efe8e2;
      cursor: pointer;
      z-index: 3;
    `,
    StepProgressSidebar: styled.div`
      height: 100%;
      width: 360px;
      max-width: 30%;
      display: flex;
      flex-direction: column;
      background-color: ${(props) => props.theme.colors.beige};
      padding: 40px 40px 40px 50px;
      justify-content: space-between;
      background-image: url(${CreateBackground});
      @media ${(props) => props.theme.breakpoints.sm} {
        width: 100%;
        height: 149px;
        max-width: unset;
        background-size: cover;
        padding: 24px 0 14px;
        align-items: center;
      }
    `,
    StepRow: styled.div`
      display: flex;
      @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: column;
        align-items: center;
      }
    `,
    StepTrack: styled.div`
      display: flex;
      flex-direction: column;
      @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        max-width: 500px;
        margin: 0 auto;
        padding: 0 14px;
      }
    `,
    StepContainer: styled.div`
      height: 100%;
      flex: 1;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      overflow: auto;
      @media (max-width: 1068px) {
        padding: 150px 15px 20px;
        height: auto;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none; 
        ::-webkit-scrollbar {
          display: none;
        }
      }
      @media ${(props) => props.theme.breakpoints.sm} {
        justify-content: flex-start;
        padding: 31px 15px 20px;
        height: auto;
      }
    `,
    Heading1: styled.h1`
      ${(props) => props.theme.typography.h1};
      margin-bottom: 20px;
      max-width: 85%;
      text-align: center;
      @media ${(props) => props.theme.breakpoints.sm} {
        font-size: 30px;
      }
    `,
    Heading2: styled.h2`
      text-align: center;
      ${(props) => props.theme.typography.h2};
      margin-bottom: 60px;
      font-weight: 300;
    `,
    Subtext: styled.p`
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
      color: ${(props) => props.theme.colors.neutral.dark};
      font-weight: 300;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        text-align: center;
      }
    `,
    Logo: styled.img`
      height: 37px;
      width: 140px;
      @media ${(props) => props.theme.breakpoints.sm} {
        margin: 0 auto 33px;
        height: 28px;
        width: 106px;
      }
    `,
    Step: styled.p<{ $active: boolean }>`
      color: ${(props) => props.theme.colors.neutral.dark};
      font-size: 16px;
      font-weight: ${(props) => (props.$active ? "500" : "300")};
      @media ${(props) => props.theme.breakpoints.sm} {
        font-size: 12px;
      }
    `,
    StepNumber: styled.div<{ $complete: boolean; $active: boolean }>`
      height: 29px;
      min-width: 29px;
      background-color: ${(props) =>
        props.$active
          ? props.theme.colors.primary.main
          : props.$complete
          ? "#000"
          : "transparent"};
      color: ${(props) =>
        props.$active
          ? props.theme.colors.creme
          : props.theme.colors.neutral.dark};
      border-radius: 50%;
      margin-right: 14px;
      border: 1px solid #dbd0c9;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: ${(props) => (props.$active ? "500" : "300")};
      @media ${(props) => props.theme.breakpoints.sm} {
        margin: 0 0 4px 0;
      }
    `,
    ImportSelectBox: styled.div<{ $selected: boolean }>`
      width: 230px;
      height: 219px;
      border-radius: 14px;
      border: 1px solid
        ${(props) =>
          props.$selected ? props.theme.colors.primary.main : "#f1e6e0"};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0 38px;
      cursor: pointer;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 155px;
        width: 163px;
        font-size: 16px;
        padding: 0 20px;
        & .MuiOutlinedInput-input {
          font-size: 14px;
        }
      }
    `,
    ImportIcon: styled.img`
      height: 63px;
      width: auto;
      margin-bottom: 24px;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 44px;
        margin-bottom: 17px;
      }
    `,
    ImportUploadIcon: styled(FontAwesomeIcon)`
      height: 63px;
      width: auto;
      margin-bottom: 24px;
      color: #7689ff;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 44px;
        margin-bottom: 17px;
      }
    `,
    ImportDesc: styled.p`
      font-size: 18px;
      line-height: 20px;
      font-weight: 300;
      text-align: center;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        font-size: 15px;
        line-height: 18px;
      }
    `,
    SelectContainer: styled.div<{ $selected: boolean }>`
      height: 28px;
      width: 28px;
      border: 1px solid
        ${(props) =>
          props.$selected ? props.theme.colors.primary.main : "#DFCFC4"};
      border-radius: 50%;
      position: absolute;
      background-color: ${(props) =>
        props.$selected ? props.theme.colors.primary.main : "transparent"};
      top: 7px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 20px;
        width: 20px;
      }
    `,
    ContinueButton: styled.button<{ $enabled: boolean }>`
      width: 100%;
      max-width: 230px;
      height: 48px;
      box-shadow: none;
      display: flex;
      background-color: ${(props) =>
        props.$enabled ? props.theme.colors.primary.main : "#E7E2DF"};
      font-size: 18px;
      font-weight: 400;
      font-family: inherit;
      color: #fff;
      margin: 0 auto;
      border: none;
      border-radius: 43.5px;
      align-items: center;
      justify-content: space-between;
      padding: 0 9.5px 0 22px;
      cursor: pointer;
      @media ${(props: any) => props.theme.breakpoints.md} {
        font-size: 16px;
        max-width: 212px;
        min-height: 46px;
      }
      @media ${(props: any) => props.theme.breakpoints.sm} {
        font-size: 15px;
        max-width: 212px;
        min-height: 46px;
      }
    `,
    SubIcon: styled.img`
      margin-right: 11px;
      height: 20px;
    `,
    DocumentGrid: styled(Grid).attrs({ container: true })`
      & .MuiGrid-root {
        @media ${(props: any) => props.theme.breakpoints.sm} {
          min-width: 100%;
        }
      }
    `,
    GridItem: styled(Grid).attrs({ item: true })``,
  },
  // Onboarding customizer
  OBCustomizer: {
    Dialog: styled(Dialog)``,
    Container: styled.div`
      width: 100%;
      height: 360px;
      max-width: 704px;
      display: flex;
      margin-bottom: 53px;
      @media ${(props: any) => props.theme.breakpoints.md} {
        flex-direction: row;
        height: auto;
        margin-bottom: 20px;
        padding-bottom: 20px;
        align-items: center;
      }
      @media ${(props: any) => props.theme.breakpoints.sm} {
        flex-direction: column;
        height: 550px;
        margin-bottom: 0;
      }
      @media (max-width: 588px) and (min-width: 0px) {
        height: 550px;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    `,
    Preview: styled.div`
      height: 100%;
      flex: 1;
      min-width: 57%;
      position: relative;
      @media ${(props: any) => props.theme.breakpoints.md} {
        margin-bottom: 20px;
      }
      @media ${(props: any) => props.theme.breakpoints.sm} {
        margin-bottom: 20px;
        width: 345px;
      }
    `,
    PreviewBG: styled.div`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 18px;
      background: linear-gradient(180deg, #fff6f0 0%, #f0f2ff 100%);
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 24px;
      @media ${(props: any) => props.theme.breakpoints.md} {
        position: relative;
        padding: 19px;
        width: 100%;
      }
      @media ${(props: any) => props.theme.breakpoints.sm} {
        position: relative;
        padding: 19px;
      }
    `,
    OptionContainer: styled.div`
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 18px;
      margin-left: 20px;

      @media ${(props: any) => props.theme.breakpoints.md} {
        flex-direction: column;
        width: 100%;
        padding: 0;
      }
      @media ${(props: any) => props.theme.breakpoints.sm} {
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
      }
    `,
    OptionBox: styled.div`
      height: 79px;
      width: 273px;
      border-radius: 14px;
      border: 1px solid #f1e6e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      padding: 0 19px 0 23px;
      position: relative;
      cursor: pointer;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.primary.main};
      }
      @media ${(props: any) => props.theme.breakpoints.md} {
        width: 100%;
      }
      @media ${(props: any) => props.theme.breakpoints.sm} {
        width: 100%;
        height: 50%;
        padding: 10px;
      }
    `,
    ImageContainer: styled.div<{ $size?: string; $bgImage: string }>`
      height: ${(props) => (props.$size ? `${props.$size}px` : `50px`)};
      width: ${(props) => (props.$size ? `${props.$size}px` : `50px`)};
      background: url(${(props) => props.$bgImage});
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      background-color: #fff;
      overflow: hidden;
      box-shadow: 0 0 6px 0 rgba(118, 137, 255, 0.29);
    `,
    IconContainer: styled.div`
      height: 38px;
      width: 38px;
      background-color: ${(props) => props.theme.colors.primary.main};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    CurrentColor: styled.div<{ $bgColor: string }>`
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: ${(props) => props.$bgColor};
      margin-left: 1px;
    `,
    Label: styled.p`
      font-size: 16px;
      line-height: 22px;
      flex: 1;
      text-align: left;
      padding-left: 13px;
    `,
    SubText: styled.p`
      font-size: 12px;
      opacity: 0.5;
    `,
    // Preview Text Box
    ChatBox: styled.div`
      height: 315px;
      width: 298px;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      z-index: 1;
      background-color: #fff;
      margin-right: 10px;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 240px;
        flex: 1;
        max-width: 330px;
      }
    `,
    TopBar: styled.div<{ $bgColor: string }>`
      width: 100%;
      height: 71px;
      display: flex;
      padding-left: 17px;
      align-items: center;
      background-color: ${(props) => props.$bgColor};
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 62px;
      }
    `,
    ChatTitle: styled.p<{ $color: string }>`
      font-size: 16px;
      color: ${(props) => props.$color};
      font-weight: 400;
      margin-left: 10px;
      // text truncate
      max-width: 70%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `,
    ChatButton: styled.div<{ $bgImage: string }>`
      box-shadow: 0 0 7px 0 rgba(118, 137, 255, 0.29);
      height: 55px;
      width: 55px;
      min-width: 55px;
      border-radius: 50%;
      background: url(${(props) => props.$bgImage});
      background-size: cover;
      background-position: center;
      overflow: hidden;
    `,
    ChatSection: styled.div`
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 20px 23px 16px;
      justify-content: space-between;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        padding: 17px;
      }
    `,
    ChatBubble: styled.div`
      position: relative;
      background-color: #f3f4f9;
      border-radius: 14px 14px 14px 0;
      padding: 14px 20px 17px 28px;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        padding: 14px 7px;
        font-size: 12px;
        line-height: 15px;
      }
    `,
    ExampleAvatar: styled.div<{ $bgImage: string }>`
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background: url(${(props) => props.$bgImage});
      background-size: cover;
      background-position: center;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: -12.5px;
      background-color: ${(props) => props.theme.colors.primary.main};
      @media ${(props: any) => props.theme.breakpoints.sm} {
        bottom: -12.5px;
      }
    `,
    SendButton: styled.div<{ $bgColor: string }>`
      height: 38px;
      width: 38px;
      background-color: ${(props) => props.$bgColor};
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-left: 8px;
      @media ${(props: any) => props.theme.breakpoints.sm} {
        height: 35px;
        width: 35px;
      }
    `,
    CreationModal: styled(Dialog)`
      & .MuiPaper-root {
        height: 100%;
        width: 90%;
        max-width: 798px;
        max-height: 471px;
        background-color: rgba(255, 255, 255);
        box-shadow: 0 2px 37px 0 rgba(41, 51, 107, 0.18);
        border-radius: 34px;
        margin: 0 auto;
        background-image: url(${ModalBg});
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 47px 24px 0;
      }
    `,
    ModalTitle: styled.h2`
      ${(props) => props.theme.typography.h2};
      max-width: 450px;
      margin: 30px 0 44px;
      font-weight: 300;
      text-align: center;
    `,
    CloseButton: styled.button`
      height: 50px;
      width: 287px;
      border: 1px solid ${(props) => props.theme.colors.primary.main};
      border-radius: 25px;
      font-size: 18px;
      font-weight: 400;
      font-family: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 11px 0 21px;
      background-color: #fff;
      color: ${(props) => props.theme.colors.primary.main};
      cursor: pointer;
    `,
  },
  Accordion: {
    Accordion: styled(Accordion)``,
    AccordionSummary: styled(AccordionSummary)`
      & .MuiAccordionSummary-content {
      }
    `,
    AccordionDetails: styled(AccordionDetails)``,
  },
};

export default LayoutStyles;
