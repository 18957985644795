import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { loginUser, initJWT, getUserData } from "../../actions";
import logoIcon from "../../assets/images/branding/infobox/logoIcon.png";
import Google from "../../assets/images/branding/google/Google.png";
import { LoginStyles } from "./LoginStyles";
import { Checkbox } from "@mui/material";
import {
  faEye,
  faEyeSlash,
  faArrowCircleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { CurrentUser } from "../../types/AuthTypes";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClient } from "../../context/ClientContext";
import EmailIcon from "../../assets/icons/email.png";
import SplashStyles from "../../pages/SplashPage/SplashStyles";
import ForgotPasswordModal from "./ForgotPassword/ForgotPasswordModal";
import { useTheme } from "styled-components";
import { useMediaQuery } from "@mui/material";

const LoginModal = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailSelected, setEmailSelected] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const {
    authorized,
    currentUser,
    setLoginOpen,
    setForgotOpen,
    setAuthorized,
    setJsonWebToken,
    setCurrentUser,
    isForgotOpen,
  } = useClient();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const storedData = localStorage.getItem("mobile");

    if (queryParameters.get("mobile") === "true" || storedData === "true") {
      const backButton = document.getElementById("back-button") as HTMLElement;
      if (!backButton) return;
      backButton.style.display = "none";
      if (queryParameters.get("mobile")) {
        localStorage.setItem("mobile", "true");
      }
    }
  }, [isEmailSelected]);

  const validate = (values: { email: string; password: string }) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = "This field is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "This field is required";
    }
    return errors;
  };

  const handleShowPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleForgotClick = () => {
    setLoginOpen(false);
    setForgotOpen(true);
  };

  useEffect(() => {
    if (authorized && currentUser.userID) {
      const isOnboarded = currentUser.onboarded;
      navigate(isOnboarded ? "/data" : "/create", { replace: true });
    }
  }, [authorized, currentUser]);


  const handleLogin = async (values: {
    email: string;
    password: string;
    agreement: boolean;
  }) => {
    loginUser({
      email: values.email,
      password: values.password,
    })
      .then((res: any) => {
        let accessToken = res.ENCODING;
        if (res.result === "FAILED" && res.error === "password is incorrect") {
          formik.errors.password = "";
          setPasswordError("Incorrect password");
        }
        if (res.result === "SUCCESS") {
          localStorage.setItem("JWT_TOKEN", res.JWT_TOKEN);
          localStorage.setItem("REFRESH_TOKEN", res.REFRESH_TOKEN);
          if (accessToken) {
            initJWT(res.JWT_TOKEN).then(() => {
              setJsonWebToken(res.JWT_TOKEN);
              setAuthorized(true);
              getUserData().then((res: any) => {
                const isOnboarded = res.content.onboarded;
                setCurrentUser(res.content as CurrentUser);
                navigate(isOnboarded ? "/data" : "/create", { replace: true });
              });
            });
          }
        }
      })
      .catch((error: Error) => console.log("login error", error));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      agreement: false,
    },
    validate,
    onSubmit: (values, props) => {
      handleLogin(values);
      props.setSubmitting(false);
    },
  });

  function previous() {
    window.location.href = process.env.REACT_APP_REDIRECT as string;
  }
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.get("type") === "mobile") {
      const backButton = document.getElementsByClassName("back-button")[0] as HTMLElement;
      if (backButton){
        backButton.style.display = "none";
      }
    }
  }, []);

  const handleSignUpOpen = () => {
    navigate('/auth/signup');
  };

  return (
    <LoginStyles.Container>
      <img
        style={isEmailSelected ? { marginBottom: "15px" } : {}}
        className="logo"
        src={logoIcon}
        alt="logo-icon"
      />
      {!isEmailSelected && (
        <>
          <SplashStyles.BackIcon
          id="back-button">
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => previous()}
              icon={faArrowLeftLong}
              size="xl"
              />
          </SplashStyles.BackIcon>
          <SplashStyles.FlexColumn
            $justifyContent="space-between"
            style={{ minHeight: "250px" }}
          >
            <h1 style={isEmailSelected ? { marginBottom: "30px" } : {}}>
              Log into your account
            </h1>
            <div className="button-container">
              <button
                className="auth-buttons"
                style={{ marginBottom: "3px" }}
                onClick={() => {
                  const popupWindow = window.open(
                    `${
                      process.env.REACT_APP_INFOBOX_USERS
                    }/google_auth/authorize/?env=${
                      window.location.origin.includes("localhost:")
                        ? "localhost"
                        : process.env.REACT_APP_ENVIRONMENT
                    }`,
                    "_blank",
                    "width=500,height=600,toolbar=no"
                  );
                  const handleMessage = (event: MessageEvent<any>) => {
                    const message = event.data;
                    if (message && event.source === popupWindow) {
                      let res = JSON.parse(message);
                      if (
                        res.result === "SUCCESS" &&
                        localStorage.getItem("JWT_TOKEN")
                      ) {
                        initJWT(localStorage.getItem("JWT_TOKEN") as string)
                          .then(() => {
                            setJsonWebToken(
                              localStorage.getItem("JWT_TOKEN") as string
                            );
                            getUserData().then((res: any) => {
                              const isOnboarded = res.content.onboarded;
                              setCurrentUser(res.content as CurrentUser);
                              setAuthorized(true);
                              navigate(isOnboarded ? "/data" : "/create", {
                                replace: true,
                              });
                            });
                          })
                          .catch((err: any) => console.error(err));
                      }
                    }
                  };
                  window.addEventListener("message", handleMessage);
                }}
              >
                <img
                  className="auth-icons"
                  src={Google}
                  alt=""
                />
                <div>Continue with Google</div>
              </button>
            </div>

            <div className="line-break">
              <hr />
              <div>or</div>
            </div>
            <div
              style={{ marginTop: "0" }}
              className="button-container"
            >
              <button
                className="auth-buttons"
                onClick={() => {
                  setEmailSelected(true);
                }}
              >
                <img
                  className="auth-icons"
                  src={EmailIcon}
                  alt=""
                />
                <div>Sign in with Email</div>
              </button>
            </div>
          </SplashStyles.FlexColumn>
        </>
      )}
      {isEmailSelected && (
        <>
          <SplashStyles.BackIcon>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => setEmailSelected(false)}
              icon={faArrowLeftLong}
              size="xl"
            />
          </SplashStyles.BackIcon>
          <form onSubmit={formik.handleSubmit}>
            <h1 style={isEmailSelected ? { marginBottom: "30px" } : {}}>
              Log into your account
            </h1>
            <LoginStyles.TextFieldWrapper>
              <LoginStyles.TextField
                placeholder="Email"
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="errors">{formik.errors.email}</div>
              )}
            </LoginStyles.TextFieldWrapper>
            <LoginStyles.TextFieldWrapper>
              <LoginStyles.TextField
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                InputProps={{
                  endAdornment: showPassword ? (
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleShowPassword}
                      icon={faEyeSlash}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleShowPassword}
                      icon={faEye}
                    />
                  ),
                }}
              />
              {!formik.errors.password && passwordError && (
                <div className="errors">{passwordError}</div>
              )}
              {formik.touched.password && formik.errors.password && (
                <div className="errors">{formik.errors.password}</div>
              )}
            </LoginStyles.TextFieldWrapper>
            <div className="remember-forgot">
              <label
                htmlFor="remember-me"
                className="remember-me"
              >
                <Checkbox
                  name="agreement"
                  checked={formik.values.agreement}
                  onChange={formik.handleChange}
                />
                Remember Me
              </label>
              <LoginStyles.ColoredLink onClick={handleForgotClick}>
                Forgot Password?
              </LoginStyles.ColoredLink>
            </div>
            <LoginStyles.SubmitButton
              type="submit"
              onClick={() => handleLogin(formik.values)}
              disabled={!formik.isValid && formik.dirty}
              $isValid={formik.isValid}
              $dirty={formik.dirty}
              style={{
                marginBottom: '0px'
              }}
            >
              <div>Login</div>
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                size="xl"
              />
            </LoginStyles.SubmitButton>
          </form>
        </>
      )}
      {isForgotOpen && <ForgotPasswordModal />}
      <LoginStyles.Subtext style={{margin: "30px 0 40px 0"}}>
        Don't have an account? <span onClick={handleSignUpOpen}>Sign up</span>
      </LoginStyles.Subtext>
    </LoginStyles.Container>
  );
};

export default LoginModal;
