import {
  faArrowsRotate,
  faArrowUpFromBracket,
  faArrowUpRight,
  faBrowser,
  faCircleCheck,
  faCircleMinus,
  faCircleXmark,
  faFile,
  faFilePdf,
  faMinus,
  faPlus,
  faRotateRight,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { toggleChatbotSource } from "../../actions";
import LayoutStyles from "../../App.styles";
import DocIcon from "../../assets/icons/doc.png";
import SlideIcon from "../../assets/icons/slides.png";
import SheetIcon from "../../assets/icons/google sheet.svg"
import { useClient } from "../../context/ClientContext";
import { SourceType } from "../../types/AuthTypes";
import Tooltip from "@mui/material/Tooltip";
import { useNotifications } from "../../context/NotificationContext";
import { NotificationDataTypes } from "../../types/NotificationTypes";
import { toastMessages } from "../../utils/toastMessages";

const DocCard = ({
  name,
  lastEdited,
  fileType,
  source,
  deleteSource,
  synced,
  maxWidth
}: {
  name: string;
  lastEdited: number;
  fileType: string;
  source?: SourceType;
  progress?: number;
  uploaded?: boolean;
  active?: boolean;
  deleteSource?: (sourceID: string) => void;
  synced?: boolean;
  maxWidth?: string;
}) => {
  const { DocCard } = LayoutStyles;
  
  const { dispatch } = useNotifications();
  const { currentChatbot, syncSource, sources, setSources } = useClient();
  const [show, setShow] = useState(false);
  const [showProg, setShowProg] = useState(false);
  const [progress, setProgress] = useState(-1);
  const [showComplete, setShowComplete] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [anchorPosition, setAnchorPosition] = useState<
    | {
        top: number;
        left: number;
      }
    | undefined
  >(undefined);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!source) return;
    setAnchorEl(event.currentTarget);
    setShow(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function timeAgo(lastEditedTimestamp: number) {
    const currentTime = Date.now();
    const timeDifference = currentTime - lastEditedTimestamp;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `Uploaded ${years} ${years === 1 ? "year" : "years"} ago`;
    } else if (months > 0) {
      return `Uploaded ${months} ${months === 1 ? "month" : "months"} ago`;
    } else if (weeks > 0) {
      return `Uploaded ${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (days > 0) {
      return `Uploaded ${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (hours > 0) {
      return `Uploaded ${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (minutes > 0) {
      return `Uploaded ${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else {
      return `Uploaded ${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
    }
  }

  const getIcon = () => {
    switch (fileType) {
      case "doc":
        return (
          <div style={{ height: "37px" }}>
            <DocCard.Icon src={DocIcon} />
          </div>
        );
      case "pdf":
        return (
          <FontAwesomeIcon
            icon={faFilePdf}
            color={"#ba002f"}
            style={{ height: 35, marginRight: 12 }}
          ></FontAwesomeIcon>
        );
      case "website":
        return (
          <FontAwesomeIcon
            icon={faBrowser}
            color={"#a7afdd"}
            style={{ height: 32, marginRight: 12 }}
          ></FontAwesomeIcon>
        );
      case "slide":
        return (
          <div style={{ height: "37px" }}>
            <DocCard.Icon src={SlideIcon} />
          </div>
        );
      case "sheet":
        return (
          <div>
            <DocCard.Icon src={SheetIcon} />
          </div>
        )
      default:
        return (
          <FontAwesomeIcon
            icon={faFile}
            color={"#8f8f8f"}
            style={{ height: 35, marginRight: 12 }}
          ></FontAwesomeIcon>
        );
    }
  };

  const toggleSource = () => {
    if (!source) return;
    toggleChatbotSource({
      chatbotID: currentChatbot.chatbotID,
      sourceID: source?.sourceID,
    }).then((res: { result: string; data: boolean }) => {
      if (res.result === "SUCCESS") {
        setSources((prev) =>
          prev.map((sourceObj, index) =>
            source?.sourceID === sourceObj.sourceID
              ? { ...sourceObj, active: res.data }
              : sourceObj
          )
        );
        const { message, status } = toastMessages(res.data ? 'FILE_ACTIVATED' : 'FILE_DEACTIVATED')
        dispatch({
          type: NotificationDataTypes.SET_TOAST,
          payload: {
            open: true,
            message: message,
            status: status
          }
        })
      }
    });
    handleClose();
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl, anchorPosition]);

  useEffect(() => {
    setShowComplete(true);
    if (
      source?.upload_status.type === "queued" ||
      source?.upload_status.type === "in_progress"
    ) {
      setShowProg(true);
    }
    if (source?.upload_status.type === "failed") {
      setShowProg(false);
    }
  }, [source?.upload_status.type]);

  const getSubtext = () => {
    switch (source?.upload_status.type) {
      case "completed":
        return timeAgo(lastEdited);
      case "in_progress":
        return "Uploading...";
      case "queued":
        return "Queued";
      case "failed":
        return "Failed upload";
    }
    return timeAgo(lastEdited);
  };

  useEffect(() => {
    switch (source?.upload_status.type) {
      case "completed":
        setProgress(163);
        break;
      case "in_progress":
        setProgress(81.5);
        break;
      case "queued":
        setProgress(0);
        break;
    }
  }, [source?.upload_status.type]);

  return (
    <Tooltip
      title={name}
      arrow
      open={show}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <DocCard.Box
        whileTap={{ scale: 0.95 }}
        whileHover={
          source ? { scale: 1.02, borderColor: "#BFC8FF" } : undefined
        }
        $synced={synced ? synced : false}
        $uploaded={source?.active}
        $maxWidth={maxWidth}
        onClick={handleClick}
        style={{ cursor: source ? "pointer" : "initial", }}
      >
        {!synced && (
          <DocCard.NewVersionBar>
            New version available
            <FontAwesomeIcon icon={faArrowsRotate} />
          </DocCard.NewVersionBar>
        )}
        <DocCard.Wrapper>
          {getIcon()}
          <div>
            <DocCard.Name>{name}</DocCard.Name>
            <DocCard.LastUpdated>
              {showComplete && source?.upload_status.type === "completed"
                ? "Uploaded successfully"
                : getSubtext()}
            </DocCard.LastUpdated>
          </div>
        </DocCard.Wrapper>

        <DocCard.Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            style: {
              borderRadius: 13,
              padding: "0px 8px",
              marginTop: 10,
            },
          }}
        >
          {source?.upload_status.type !== "failed" && (
            <DocCard.MenuItem
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (source) {
                  window.open(source.url, "_blank");
                }
              }}
            >
              <DocCard.MenuIcon>
                <FontAwesomeIcon icon={faArrowUpRight} style={{ height: 15 }} />
              </DocCard.MenuIcon>
              <div>
                <DocCard.MenuTextTitle>Open</DocCard.MenuTextTitle>
                <DocCard.MenuTextSubTitle>
                  Open in a new window
                </DocCard.MenuTextSubTitle>
              </div>
            </DocCard.MenuItem>
          )}
          {source?.upload_status.type !== "failed" && (
            <DocCard.MenuItem onClick={toggleSource}>
              {" "}
              <DocCard.MenuIcon>
                <FontAwesomeIcon
                  icon={source?.active ? faMinus : faPlus}
                  style={{ height: 13 }}
                />
              </DocCard.MenuIcon>
              <div>
                <DocCard.MenuTextTitle>
                  {source?.active ? "Deactivate" : "Activate"}
                </DocCard.MenuTextTitle>
                <DocCard.MenuTextSubTitle>
                  {source?.active
                    ? " Exclude from knowledge base"
                    : " Include in knowledge base"}
                </DocCard.MenuTextSubTitle>
              </div>
            </DocCard.MenuItem>
          )}
          {source?.sourceType !== "file-upload" && (
            <DocCard.MenuItem
              onClick={() => {
                if (source) {
                  syncSource(source?.sourceID);
                }
              }}
            >
              <DocCard.MenuIcon>
                <FontAwesomeIcon
                  icon={
                    source?.upload_status.type !== "failed"
                      ? faArrowUpFromBracket
                      : faRotateRight
                  }
                  style={{ height: 13 }}
                />
              </DocCard.MenuIcon>
              <div>
                <DocCard.MenuTextTitle>
                  {source?.upload_status.type !== "failed"
                    ? "Re-sync"
                    : "Retry"}
                </DocCard.MenuTextTitle>
                <DocCard.MenuTextSubTitle>
                  {source?.upload_status.type !== "failed"
                    ? "Upload the newest version"
                    : "Try uploading source again."}
                </DocCard.MenuTextSubTitle>
              </div>
            </DocCard.MenuItem>
          )}
          <DocCard.MenuItem
            onClick={() => {
              if (deleteSource && source) {
                deleteSource(source?.sourceID);
              }
            }}
          >
            {" "}
            <DocCard.MenuIcon>
              <FontAwesomeIcon icon={faXmark} style={{ height: 14 }} />
            </DocCard.MenuIcon>
            <div>
              <DocCard.MenuTextTitle>Delete</DocCard.MenuTextTitle>
              <DocCard.MenuTextSubTitle>
                Remove from knowledge base
              </DocCard.MenuTextSubTitle>
            </div>
          </DocCard.MenuItem>
        </DocCard.Menu>

        <DocCard.ProgressContainer
          animate={{
            opacity: showProg ? 1 : 0,
          }}
          initial={{ opacity: 0 }}
          transition={{ delay: 1 }}
        >
          <DocCard.ProgressBar
            animate={{ x: -163 + progress }}
            transition={{ duration: 1 }}
            onAnimationComplete={() => {
              if (progress === 163) setShowProg(false);
            }}
          />
        </DocCard.ProgressContainer>
        <DocCard.UploadCompleteCheck
          animate={{
            opacity: !showProg ? 1 : 0,
          }}
          initial={{ opacity: 0 }}
          transition={{ delay: 1 }}
        >
          {source?.upload_status.type === "completed" &&
            source?.active &&
            showComplete && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={"#23CD8F"}
                height={14}
              />
            )}
          {source?.upload_status.type === "failed" && (
            <FontAwesomeIcon
              icon={faCircleXmark}
              color={"#c54013"}
              height={14}
            />
          )}
          {!source?.active && source?.upload_status.type === "completed" && (
            <FontAwesomeIcon
              icon={faCircleMinus}
              color={"#9d9d9d"}
              height={14}
            />
          )}
        </DocCard.UploadCompleteCheck>
      </DocCard.Box>
    </Tooltip>
  );
};

export default DocCard;
