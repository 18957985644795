import { useState } from "react";
import LayoutStyles from "../../../App.styles";
import GoogleIcon from "../../../assets/icons/googledocs.png";
import WebsiteIcon from "../../../assets/icons/website.png";
import { Accordion, Skeleton } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DocCard from "../../Cards/DocCard";
import { SourceType } from "../../../types/AuthTypes";
import { dateToUtcNumber } from "../../../helpers/dateConversion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowUp,
  faUpFromLine,
} from "@fortawesome/pro-solid-svg-icons";
import { useClient } from "../../../context/ClientContext";
import AddGoogleFiles from "../../AddGoogleFiles/AddGoogleFiles";
import AddWebsiteFiles from "../../AddWebsiteFiles/AddWebsiteFiles";
import AddLocalFiles from "../../AddLocalFiles/AddLocalFiles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";

const AllSourcesAccordion = ({
  sources,
  importFiles,
  deleteSource,
}: {
  sources: SourceType[];
  importFiles: () => Promise<void>;
  deleteSource: (sourceID: string) => void;
}) => {
  const { LibrarySection } = LayoutStyles;
  const { loading } = useClient();
  const [uploadOpen, setUploadOpen] = useState<
    "website" | "google-drive" | "one-drive" | "file-upload" | ""
  >("");
  const theme: any = useTheme();
  const useMobileLayout = useMediaQuery(theme.breakpoints.sm);

  const googleSources = sources.filter(
    (source) => source.sourceType === "google-drive"
  );
  const websiteSources = sources.filter(
    (source) => source.sourceType === "website"
  );

  const uploadSources = sources.filter(
    (source) => source.sourceType === "file-upload"
  );

  return (
    <>
      <LibrarySection.DocumentSection
      style={{
        marginTop: '20px'
      }}>
        {useMobileLayout && (
          <Accordion
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              boxShadow: 'none',
            }}
            >
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#20243A'}}/>}
            sx={{
              display:'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              maxHeight: '75px',

            }}>
              <LibrarySection.TitleSection
              
           >
                <LibrarySection.LibraryTitle>
                  <LibrarySection.IntegrationIcon src={GoogleIcon} />
                  Google Drive
                </LibrarySection.LibraryTitle>
                <LibrarySection.UploadButton
                  onClick={() => {
                    setUploadOpen("");
                    setUploadOpen("google-drive");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCircleArrowUp}
                    style={{ height: 18 }}
                  />
                  Upload files
                </LibrarySection.UploadButton>
                <AddGoogleFiles
                  opened={uploadOpen}
                  setOpened={setUploadOpen}
                  importFiles={importFiles}
                />
              </LibrarySection.TitleSection>
            </AccordionSummary>
            <AccordionDetails>
              {!loading ? (
                <LibrarySection.DocumentRow container
                style={{
                  minWidth: '100%'
                }}>
                  {sources
                    .filter((source) => source.sourceType === "google-drive")
                    .reverse()
                    .slice(0, 6)
                    .map((source, index) => (
                      <DocCard
                        name={source.name}
                        progress={source.progress}
                        uploaded={source.uploaded}
                        lastEdited={dateToUtcNumber(source.last_updated)}
                        key={source.sourceID}
                        fileType={source.fileType}
                        source={source}
                        deleteSource={deleteSource}
                        synced={source.synced}
                      />
                    ))}
                </LibrarySection.DocumentRow>
              ) : (
                <LibrarySection.DocumentRow container>
                  {Array(4)
                    .fill("")
                    .map((_, index) => (
                      <Skeleton key={index}>
                        <div
                          style={{
                            minHeight: 73,
                            width: 195,
                            borderRadius: 14,
                          }}
                        />
                      </Skeleton>
                    ))}
                </LibrarySection.DocumentRow>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {!useMobileLayout && (
          <>
            <LibrarySection.TitleSection>
              <LibrarySection.LibraryTitle>
                <LibrarySection.IntegrationIcon src={GoogleIcon} />
                Google Drive
              </LibrarySection.LibraryTitle>
              <LibrarySection.UploadButton
                onClick={() => {
                  setUploadOpen("");
                  setUploadOpen("google-drive");
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleArrowUp}
                  style={{ height: 18 }}
                />
                Upload files
              </LibrarySection.UploadButton>
              <AddGoogleFiles
                opened={uploadOpen}
                setOpened={setUploadOpen}
                importFiles={importFiles}
              />
            </LibrarySection.TitleSection>
            <LibrarySection.SubTitle
              style={{
                marginBottom: 15,
                display: googleSources.length ? "block" : "none",
              }}
            >
              Recently uploaded files
            </LibrarySection.SubTitle>
          </>
        )}

        {!loading ? (
          !useMobileLayout && (
            <LibrarySection.DocumentRow container>
              {sources
                .filter((source) => source.sourceType === "google-drive")
                .reverse()
                .slice(0, 6)
                .map((source, index) => (
                  <DocCard
                    name={source.name}
                    progress={source.progress}
                    uploaded={source.uploaded}
                    lastEdited={dateToUtcNumber(source.last_updated)}
                    key={source.sourceID}
                    fileType={source.fileType}
                    source={source}
                    deleteSource={deleteSource}
                    synced={source.synced}
                  />
                ))}
            </LibrarySection.DocumentRow>
          )
        ) : (
          <LibrarySection.DocumentRow container>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <Skeleton key={index}>
                  <div
                    style={{ minHeight: 73, width: 195, borderRadius: 14 }}
                  />
                </Skeleton>
              ))}
          </LibrarySection.DocumentRow>
        )}
      </LibrarySection.DocumentSection>
      {/* <LibrarySection.DocumentSection>
        <LibrarySection.LibraryTitle>
          <LibrarySection.IntegrationIcon src={OneDrive} />
          OneDrive
        </LibrarySection.LibraryTitle>
        <LibrarySection.DocumentRow container>
          {sources
            .filter((source) => source.sourceType === "one-drive")
            .map((source, index) => (
              <DocCard
                name={source.name}
                lastEdited={dateToUtcNumber(source.last_updated)}
                key={index}
                fileType={source.fileType}
              />
            ))}
        </LibrarySection.DocumentRow>
      </LibrarySection.DocumentSection> */}
      <LibrarySection.DocumentSection>
        {useMobileLayout && (
          <Accordion
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              boxShadow: 'none',
              minHeight: '100px',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#20243A'}}/>}
             sx={{
              display:'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              maxHeight: '75px',
            }}>
              <LibrarySection.TitleSection
              style={{
                // to fix accordion expand click issue
                maxWidth: '290px',
              }}>
                <LibrarySection.LibraryTitle>
                  <LibrarySection.IntegrationIcon src={WebsiteIcon} />
                  Website
                </LibrarySection.LibraryTitle>
                <LibrarySection.UploadButton
                  onClick={() => setUploadOpen("website")}
                >
                  <FontAwesomeIcon
                    icon={faCircleArrowUp}
                    style={{ height: 18 }}
                  />
                  Import website content
                </LibrarySection.UploadButton>

                {uploadOpen === "website" && (
                  <AddWebsiteFiles
                    opened={uploadOpen}
                    setOpened={setUploadOpen}
                    importFiles={importFiles}
                  />
                )}
              </LibrarySection.TitleSection>
            </AccordionSummary>
            <AccordionDetails>
              {!loading ? (
                <LibrarySection.DocumentRow container>
                  {sources
                    .filter((source) => source.sourceType === "website")
                    .reverse()
                    .slice(0, 6)
                    .map((source, index) => (
                      <DocCard
                        name={source.name}
                        progress={source.progress}
                        uploaded={source.uploaded}
                        lastEdited={dateToUtcNumber(source.last_updated)}
                        key={source.sourceID}
                        fileType={source.fileType}
                        source={source}
                        deleteSource={deleteSource}
                        synced={source.synced}
                      />
                    ))}
                </LibrarySection.DocumentRow>
              ) : (
                <LibrarySection.DocumentRow container>
                  {Array(4)
                    .fill("")
                    .map((_, index) => (
                      <Skeleton key={index}>
                        <div
                          style={{
                            minHeight: 73,
                            width: 195,
                            borderRadius: 14,
                          }}
                        />
                      </Skeleton>
                    ))}
                </LibrarySection.DocumentRow>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {!useMobileLayout && (
          <>
            <LibrarySection.TitleSection>
              <LibrarySection.LibraryTitle>
                <LibrarySection.IntegrationIcon src={WebsiteIcon} />
                Website
              </LibrarySection.LibraryTitle>
              <LibrarySection.UploadButton
                onClick={() => setUploadOpen("website")}
              >
                <FontAwesomeIcon
                  icon={faCircleArrowUp}
                  style={{ height: 18 }}
                />
                Import website content
              </LibrarySection.UploadButton>

              {uploadOpen === "website" && (
                <AddWebsiteFiles
                  opened={uploadOpen}
                  setOpened={setUploadOpen}
                  importFiles={importFiles}
                />
              )}
            </LibrarySection.TitleSection>

            <LibrarySection.SubTitle
              style={{
                marginBottom: 15,
                display: websiteSources.length ? "block" : "none",
              }}
            >
              Recently imported URLs
            </LibrarySection.SubTitle>
          </>
        )}

        {!loading ? (
          !useMobileLayout && (
            <LibrarySection.DocumentRow container>
              {sources
                .filter((source) => source.sourceType === "website")
                .reverse()
                .slice(0, 6)
                .map((source, index) => (
                  <DocCard
                    name={source.name}
                    progress={source.progress}
                    uploaded={source.uploaded}
                    lastEdited={dateToUtcNumber(source.last_updated)}
                    key={source.sourceID}
                    fileType={source.fileType}
                    source={source}
                    deleteSource={deleteSource}
                    synced={source.synced}
                  />
                ))}
            </LibrarySection.DocumentRow>
          )
        ) : (
          <LibrarySection.DocumentRow container>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <Skeleton key={index}>
                  <div
                    style={{ minHeight: 73, width: 195, borderRadius: 14 }}
                  />
                </Skeleton>
              ))}
          </LibrarySection.DocumentRow>
        )}
      </LibrarySection.DocumentSection>

      <LibrarySection.DocumentSection>
        {useMobileLayout && (
          <Accordion
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              boxShadow: 'none',
              minHeight: '100px',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#20243A'}}/>}
             sx={{
              display:'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              maxHeight: '75px',
            }}>
              <LibrarySection.TitleSection>
                <LibrarySection.LibraryTitle>
                  <FontAwesomeIcon
                    icon={faUpFromLine}
                    style={{ marginRight: 13, color: "#7689FF" }}
                  />
                  Uploads
                </LibrarySection.LibraryTitle>
                <LibrarySection.UploadButton
                  onClick={() => {
                    setUploadOpen("");
                    setUploadOpen("file-upload");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCircleArrowUp}
                    style={{ height: 18 }}
                  />
                  Upload files
                </LibrarySection.UploadButton>
                <AddLocalFiles
                  opened={uploadOpen}
                  setOpened={setUploadOpen}
                  importFiles={importFiles}
                />
              </LibrarySection.TitleSection>
            </AccordionSummary>
            <AccordionDetails>
              {!loading ? (
                <LibrarySection.DocumentRow container>
                  {sources
                    .filter((source) => source.sourceType === "file-upload")
                    .reverse()
                    .slice(0, 6)
                    .map((source, index) => (
                      <DocCard
                        name={source.name}
                        progress={source.progress}
                        uploaded={source.uploaded}
                        lastEdited={dateToUtcNumber(source.last_updated)}
                        key={source.sourceID}
                        fileType={source.fileType}
                        source={source}
                        deleteSource={deleteSource}
                        synced={source.synced}
                      />
                    ))}
                </LibrarySection.DocumentRow>
              ) : (
                <LibrarySection.DocumentRow container>
                  {Array(4)
                    .fill("")
                    .map((_, index) => (
                      <Skeleton key={index}>
                        <div
                          style={{
                            minHeight: 73,
                            width: 195,
                            borderRadius: 14,
                          }}
                        />
                      </Skeleton>
                    ))}
                </LibrarySection.DocumentRow>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {!useMobileLayout && (
          <>
            <LibrarySection.TitleSection>
              <LibrarySection.LibraryTitle>
                <FontAwesomeIcon
                  icon={faUpFromLine}
                  style={{ marginRight: 13, color: "#7689FF" }}
                />
                Uploads
              </LibrarySection.LibraryTitle>
              <LibrarySection.UploadButton
                onClick={() => {
                  setUploadOpen("");
                  setUploadOpen("file-upload");
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleArrowUp}
                  style={{ height: 18 }}
                />
                Upload files
              </LibrarySection.UploadButton>
              <AddLocalFiles
                opened={uploadOpen}
                setOpened={setUploadOpen}
                importFiles={importFiles}
              />
            </LibrarySection.TitleSection>

            <LibrarySection.SubTitle
              style={{
                marginBottom: 15,
                display: uploadSources.length ? "block" : "none",
              }}
            >
              Recently uploaded files
            </LibrarySection.SubTitle>
          </>
        )}
        {!loading ? (
          !useMobileLayout && (
            <LibrarySection.DocumentRow container>
              {sources
                .filter((source) => source.sourceType === "file-upload")
                .reverse()
                .slice(0, 6)
                .map((source, index) => (
                  <DocCard
                    name={source.name}
                    progress={source.progress}
                    uploaded={source.uploaded}
                    lastEdited={dateToUtcNumber(source.last_updated)}
                    key={source.sourceID}
                    fileType={source.fileType}
                    source={source}
                    deleteSource={deleteSource}
                    synced={source.synced}
                  />
                ))}
            </LibrarySection.DocumentRow>
          )
        ) : (
          <LibrarySection.DocumentRow container>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <Skeleton key={index}>
                  <div
                    style={{
                      minHeight: 73,
                      width: 195,
                      borderRadius: 14,
                    }}
                  />
                </Skeleton>
              ))}
          </LibrarySection.DocumentRow>
        )}
      </LibrarySection.DocumentSection>
    </>
  );
};

export default AllSourcesAccordion;
