import styled from "styled-components";

const ModalStyles = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 24px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    @media ${props => props.theme.breakpoints.sm} {

    }
  `,
  CloseButton: styled.button`
    box-shadow: none;
    text-decoration: none;
    position: absolute;
    top: 20px;
    right: 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  `,
};

export default ModalStyles;
