import styled from "styled-components";
import { Dialog } from "@mui/material";

const FeedbackStyles = {
  Dialog: styled(Dialog)`
    height: 100%;
    width: 100%;
    z-index: 900;
    & .MuiPaper-root {
      height: 900px;
      max-width: 919px;
      min-width: 919px;
      border-radius: 30px;
      margin: 0;
      & :-webkit-scrollbar {
        display: none;
      }
      @media ${(props) => props.theme.breakpoints.md} {
        height: 100%;
        width: 100%;
        margin: 0;
        max-width: unset;
        max-height: unset;
        border-radius: 0;
        min-width: unset;
      }
      & .MuiDialogContent-root {
        padding: 0;
      }
      
    }
  `,
  Container: styled.div`
    height: 100%;
    width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  Iframe: styled.iframe`
    height: 3300px;
    width: 100%;
    @media ${(props) => props.theme.breakpoints.sm} {
      height: 3500px;
    }
  `,
};

export default FeedbackStyles;
