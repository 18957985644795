export type NotificationState = {
  toast: {
    open: boolean;
    message: string;
    status: "info" | "error" | "warning" | "success";
  };
};

export enum NotificationDataTypes {
  ERROR = "ERROR",

  SET_TOAST = "SET_TOAST",

  CLEAR_TOAST = "CLEAR_TOAST",
}

/** -> Action Interface */
interface ActionTypeInterface<T extends string> {
    type: T;
  }
  
  /** -> Action + Payload Interface */
  interface ActionTypePayloadInterface<T extends string, P> extends ActionTypeInterface<T> {
    payload: P;
  }

export namespace NotificationActionTypes {
  export type SetToast = ActionTypePayloadInterface<
  NotificationDataTypes.SET_TOAST,
    {
      open: boolean;
      message: string;
      status: "info" | "success" | "warning" | "error";
    }
  >;
  export type ClearToast =
    ActionTypeInterface<NotificationDataTypes.CLEAR_TOAST>;
  export type Error = ActionTypePayloadInterface<
  NotificationDataTypes.ERROR,
    string
  >;

  export type AllReducer =
    | SetToast
    | ClearToast
    | Error;
}


export type NotificationContext = {
    dispatch: React.Dispatch<NotificationActionTypes.AllReducer>;
    notificationState: NotificationState;
  };
