import { useState, useEffect } from "react";
import SignUpOptions from "./SignUpOptions";
import SignUpWithEmail from "./SignUpWithEmail";
import InfoBoxLogo from "../../../assets/images/infoboxLogoBeta.png"
import { LoginStyles } from "../LoginStyles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { useClient } from "../../../context/ClientContext";
import SplashStyles from "../../../pages/SplashPage/SplashStyles";
import {
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";


const SignUpModal = () => {
  const [emailSignUpOpen, setOpenEmailSignUp] = useState(false);
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);
  const navigate = useNavigate();

  const handleEmailClick = () => {
    setOpenEmailSignUp(true);
  };

  const handleLoginOpen = () => {
    navigate('/auth/login');
  };

  function previous() {
    window.location.href = process.env.REACT_APP_REDIRECT as string;
  };

  return (
    <LoginStyles.Container
      style={{ height: emailSignUpOpen ? "fit-content" : "450px" }}
    >
                <SplashStyles.BackIcon
          id="back-button">
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => previous()}
              icon={faArrowLeftLong}
              size="xl"
              />
          </SplashStyles.BackIcon>
      {isSmallScreen && (
              <img style={{marginBottom: '20px'}} className="logo" src={InfoBoxLogo} alt="logo-icon" />
            )}
      {!emailSignUpOpen ? (
        <SignUpOptions onEmailClick={handleEmailClick} />
      ) : (
        <SignUpWithEmail
          open={emailSignUpOpen}
          setOpenEmailSignUp={setOpenEmailSignUp}
          onLoginClick={() => {}}
        />
      )}
            <SplashStyles.FlexColumn $margin="30px 0 20px 0">
        <LoginStyles.Subtext>
          Can't sign up?<a href="mailto: marketing@infobox.ai"> Contact us</a>
        </LoginStyles.Subtext>
        <LoginStyles.Subtext onClick={handleLoginOpen}>
        Already have an account?<span onClick={handleLoginOpen}> Log in</span>
      </LoginStyles.Subtext>
      </SplashStyles.FlexColumn>
    </LoginStyles.Container>
  );
};

export default SignUpModal;
