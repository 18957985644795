import { useEffect } from "react";
import { authorizeGoogleDrive, initJWT } from "../actions";

const GoogleDriveAuth = () => {
  useEffect(() => {
    initJWT(localStorage.getItem("JWT_TOKEN") as string).then(() => {
      authorizeGoogleDrive(process.env.REACT_APP_ENVIRONMENT as string).then(
        (res: any) => {
          if (res.url) {
            window.open(res.url, "_self");
          }
        }
      );
    });
  }, []);
  return <></>;
};

export default GoogleDriveAuth;
