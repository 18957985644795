import SplashStyles from "../SplashPage/SplashStyles";
import { Route, Routes } from "react-router-dom";
import LoginModal from "../../components/Login/Login";
import SignUpModal from "../../components/Login/Signup/Signup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import InfoBoxLogo from "../../assets/images/infoboxLogoBeta.png";
import LoginFooter from "../../components/Login/LoginFooter";

const Auth = () => {
  const theme: any = useTheme();
  const accessToken = new URLSearchParams(window.location.search).get("access");
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);

  const urlParams = new URLSearchParams(window.location.search);
  const encodedEmail = urlParams.get("user_invite");
  const waitlistEmail = encodedEmail
    ? JSON.parse(window.atob(encodedEmail)).email
    : "";


  return (
    <>
      <SplashStyles.Container>
        <div>
          <SplashStyles.HomeHeader style={{ justifyContent: "center" }}>
            <SplashStyles.HomeLogo src={InfoBoxLogo} />
          </SplashStyles.HomeHeader>
          <SplashStyles.HomeTitle style={{ fontSize: "48px" }}>
            Your Knowledge Powered by AI
          </SplashStyles.HomeTitle>
        </div>
        {waitlistEmail && (
          <SplashStyles.HomeSubtitle
            style={{ margin: "0 !important", maxWidth: "unset" }}
          >
            Please sign up using {waitlistEmail}
          </SplashStyles.HomeSubtitle>
        )}

        <Routes>
          <Route
            path="/"
            element={<LoginModal />}
          />
          <Route
            path="login"
            element={<LoginModal />}
          />
          <Route
            path="signup"
            element={<SignUpModal />}
          />
        </Routes>

        <SplashStyles.HomeFooter>
          {!isSmallScreen && <LoginFooter />}
        </SplashStyles.HomeFooter>
      </SplashStyles.Container>
    </>
  );
};

export default Auth;
