import styled from "styled-components";
import { Form } from "formik";
import { TextField, Dialog, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BetaStyles = {
  Container: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Form: styled(Form)`
    height: 400px;
    width: 400px;
    display: flex;
    flex-direction: column;
    border: 1px solid blue;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
  `,
  TextField: styled(TextField)`
    && {
      height: 37px;
      border: 1px solid red;
      border-radius: 20.5px;
      width: 80%;
      overflow: hidden;
      & .MuiInputBase-root {
        height: 100%;
        margin-bottom: 0;
        border-radius: 41px;
        border: none;
      }
      & .MuiOutlinedInput-input {
        height: 100%;
        border: none;
        font-size: 16px;
        color: ${(props) => props.theme.colors.neutral.dark};
        padding-left: 9px;
        font-weight: 300;
      }
      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  `,
  SubmitButton: styled(Button)`
    && {
      z-index: 3;
      height: 46px;
      text-transform: none;
      font-size: 22px;
      font-family: halyard-display;
      font-weight: 500;
      border-radius: 31px;
      color: #fff;
      background-color: ${(props) => props.theme.colors.primary.main};
      box-shadow: 0 2px 7px 0 rgba(117, 114, 109, 0.13);
      margin-top: 16.5px;
      padding: 0 20px 0 15px;
      &:hover {
        background-color: ${(props) => props.theme.colors.primary.main};
      }
    }
  `,
  ConfirmModal: {
    Container: styled(Dialog)`
      & .MuiPaper-root {
        height: 500px;
        width: 450px;
        background-color: rgba(255, 255, 255);
        box-shadow: 0 2px 37px 0 rgba(41, 51, 107, 0.18);
        border-radius: 34px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 47px;
        position: relative;
        overflow: hidden;
        @media (min-width: 351px) and (max-width: 555px) {
          width: 100%;
          max-width: 100%;
          max-height: 100%;
          border-radius: 0;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: #ffffff;
        }
        @media (min-width: 0px) and (max-width: 510px) {
          width: 100%;
          max-width: 100%;
          max-height: 100%;
          border-radius: 0;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: #ffffff;
        }
      }
      `,
    InnerContainer: styled.div`
      display: flex;
      align-items: center;
      flex-direction: column;
      @media (min-width: 0px) and (max-width: 510px) {
        background-color: #ffffff;
        top: 300px;
        height: 150vh;
      }
      `,
      LogoContainer: styled.div`
        position: relative;
        z-index: 3;
        margin-top: 8vh;
        margin-bottom: 30px;
        @media (min-width: 0px) and (max-width: 555px) {
          margin-top: 45%;
        }
      `,
      Logo: styled.img`
        height: 110px;
        width: 110px;
      `,
      Icon: styled(FontAwesomeIcon)`
        height: 35px;
        width: 35px;
        border: 2px solid #fff;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 7px;
        right: 7px;
      `,
    CopyContainer: styled.div`
      display:flex;
      flex-direction: column;
      align-items: center;
    `,

    CurveBG: styled.img`
      position: absolute;
      width: 123%;
      top: 100px;
      left: -59px;
      z-index: 2;
      @media (min-width: 0px) and (max-width: 510px) {
        left: -25%;
        min-width: 150%;
      }
    `,
    ColorBG: styled.img`
      position: absolute;
      height: 50%;
      top: 0;
      left: 0;
      @media (min-width: 0px) and (max-width: 555px) {
        width: 100%;
      }
    `,
        CoverBG: styled.div`
        @media (min-width: 0px) and (max-width: 555px) {
          background-color: #ffffff;
          position: relative;
          z-index: 5;
          width: 100%;
          height: 50%;
        }
        @media (min-width: 0px) and (max-width: 510px) {
          position: absolute;
          background-color: #ffffff;
          top: 300px;
          height: 150vh;
          z-index: 2;
        }
      `,
    Title: styled.h2`
      ${(props) => props.theme.typography.h2}
      z-index: 3;
      position: relative;
      font-weight: 300;
      max-width: 60%;
      text-align: center;
      line-height: 32px;
      @media (min-width: 351px) and (max-width: 600px) {
      }
    `,
    Subtitle: styled.p`
      z-index: 3;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
    `,
  },
};
export default BetaStyles;