import React, { useState } from "react";
import { analyzePage } from "../../actions";
import { useClient } from "../../context/ClientContext";
import LayoutStyles from "../../App.styles";
import UrlMenu from "../UrlMenu";
import ThirdPartyUpload from "./ThirdPartyUpload";
import { Integrations } from "../../context/LibraryContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { useMediaQuery } from "@mui/material";
import {
  faThumbTack,
  faCheck,
  faArrowCircleRight,
  faArrowRightLong,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import WebsiteIcon from "../../assets/icons/website.png";
import GoogleIcon from "../../assets/icons/googledocs.png";
import { LoadingAnimation } from "../../pages/SplashPage/SplashPage";
import FileUpload from "./FileUpload";
import { faUpFromLine } from "@fortawesome/pro-solid-svg-icons";
import { CustomStyles } from "../OnboardCustomizer/Customizer";

type Props = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  selectedUrls: {
    name: string;
    sourceType: string;
    sourceData: { [key: string]: any };
  }[];
  setSelectedUrls: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        sourceType: string;
        sourceData: { [key: string]: any };
      }[]
    >
  >;
  customStyles: CustomStyles;
};

const DataSelect: React.FC<Props> = ({
  setCurrentStep,
  selectedUrls,
  setSelectedUrls,
  customStyles,
}) => {
  const { CreatePage } = LayoutStyles;
  const theme: any = useTheme();
  const [selectedData, setSelectedData] = useState("");
  const [showUrls, setShowUrls] = useState(false);
  const [website, setWebsite] = useState("");
  const [analyzedUrls, setAnalyzedUrls] = useState<string[]>([]);
  const [confirmedDataSource, setConfirmedDataSource] = useState("");
  const [isLoading, setLoading] = useState(false);
  const useMobileLayout = useMediaQuery(theme.breakpoints.sm);
  const [errorMessage, setErrorMessage] = useState("");
  const isMedScreen = useMediaQuery('(max-width: 1068px)');

  const { companyName } = useClient();

  const handleImportSelect = (importSource: string) => {
    setConfirmedDataSource(importSource);
  };

  const handleAnalyze = () => {
    setLoading(true);
    let formattedUrl = website.startsWith("https")
      ? website
      : "https://" + website;
    setWebsite(formattedUrl);
    analyzePage({ url: formattedUrl })
      .then((res: { result: string; data: string[] }) => {
        if (res.result === "SUCCESS") {
          setAnalyzedUrls(res.data);
          setShowUrls(true);
          setLoading(false);
        } else if (res.result === "FAILED") {
          setLoading(false);
          setErrorMessage(
            "We are unable to import data from this website at this time"
          );
        }
      })
      .catch(() => {
        alert("Error Analyzing website, try again later");
        setLoading(false);
      });
  };

  return (
    <CreatePage.StepContainer>
      {!confirmedDataSource && (
        <CreatePage.BackButton onClick={() => setCurrentStep(2)}>
          <FontAwesomeIcon
            style={{ marginRight: "5px" }}
            icon={faArrowLeftLong}
            size="1x"
            color={theme.colors.primary.main}
          />
          Back
        </CreatePage.BackButton>
      )}
      <CreatePage.Heading2 style={{ marginBottom: "16px", marginTop: '20px' }}>
        Upload files to your knowledge library
      </CreatePage.Heading2>
      <LayoutStyles.FlexRow $margin="0 0 49px 0">
        {!useMobileLayout && (
          <FontAwesomeIcon
            icon={faThumbTack}
            color={theme.colors.primary.main}
            style={{ marginRight: "10px", marginTop: "21px" }}
          />
        )}
        <CreatePage.Subtext
        style={{ marginBottom: '-20px'}}>
          Your Assistant learns from the information you upload
        </CreatePage.Subtext>
      </LayoutStyles.FlexRow>
      {!confirmedDataSource && (
        <>
          <LayoutStyles.FlexRow
            $margin="0 0 30px 0"
            $flexDirection={isMedScreen ? 'column' : 'row'}
            style={{ gap: "1em" }}
          >
            <CreatePage.ImportSelectBox
              onClick={() => setSelectedData("website")}
              $selected={selectedData === "website"}
            >
              <CreatePage.SelectContainer
                $selected={selectedData === "website"}
              >
                <FontAwesomeIcon icon={faCheck} color="#fff" />
              </CreatePage.SelectContainer>
              <CreatePage.ImportIcon src={WebsiteIcon} alt="import website" />
              <CreatePage.ImportDesc>
                Import data from my website
              </CreatePage.ImportDesc>
            </CreatePage.ImportSelectBox>
            <CreatePage.ImportSelectBox
              onClick={() => setSelectedData("google")}
              $selected={selectedData === "google"}
            >
              <CreatePage.SelectContainer $selected={selectedData === "google"}>
                <FontAwesomeIcon icon={faCheck} color="#fff" />
              </CreatePage.SelectContainer>
              <CreatePage.ImportIcon
                src={GoogleIcon}
                alt="import documents from Google Drive"
              />
              <CreatePage.ImportDesc>
                Import data from Google Drive
              </CreatePage.ImportDesc>
            </CreatePage.ImportSelectBox>
            <CreatePage.ImportSelectBox
              onClick={() => setSelectedData("file-upload")}
              $selected={selectedData === "file-upload"}
            >
              <CreatePage.SelectContainer
                $selected={selectedData === "file-upload"}
              >
                <FontAwesomeIcon icon={faCheck} color="#fff" />
              </CreatePage.SelectContainer>

              <CreatePage.ImportUploadIcon icon={faUpFromLine} />
              <CreatePage.ImportDesc>
                Import data from your device
              </CreatePage.ImportDesc>
            </CreatePage.ImportSelectBox>
          </LayoutStyles.FlexRow>
          <CreatePage.ContinueButton
            onClick={() => handleImportSelect(selectedData)}
            $enabled={!!selectedData}
            disabled={!selectedData}
          >
            Continue
            <FontAwesomeIcon size="xl" icon={faArrowCircleRight} />
          </CreatePage.ContinueButton>
        </>
      )}

      {confirmedDataSource === "website" && (
        <>
          {!showUrls && !isLoading && (
            <>
              <CreatePage.BackButton onClick={() => setConfirmedDataSource("")}>
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faArrowLeftLong}
                  size="1x"
                  color={theme.colors.primary.main}
                />
                Back
              </CreatePage.BackButton>
              <LayoutStyles.FlexRow $margin="0 0 33px 0">
                <CreatePage.SubIcon src={WebsiteIcon} />
                Website data import
              </LayoutStyles.FlexRow>
              <div style={{ width: "518px", maxWidth: "95%" }}>
                <LayoutStyles.LargeTextField
                  placeholder="Enter your website"
                  onChange={(event) => setWebsite(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <LayoutStyles.LargeTextFieldButton
                        onClick={() => handleAnalyze()}
                        disabled={!website}
                        $enabled={!!website}
                      >
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          color="#FFF6F0"
                          size="2x"
                        />
                      </LayoutStyles.LargeTextFieldButton>
                    ),
                  }}
                />
                {errorMessage && (
                  <LayoutStyles.TextFieldError
                    style={{
                      color: "red",
                      textAlign: "left",
                      fontSize: "12px",
                      width: "100%",
                    }}
                  >
                    {errorMessage}
                  </LayoutStyles.TextFieldError>
                )}
              </div>
            </>
          )}
          {isLoading && <LoadingAnimation />}
          {showUrls && (
            <>
              <CreatePage.BackButton
                onClick={() => {
                  setWebsite("");
                  setConfirmedDataSource("");
                  setShowUrls(false);
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faArrowLeftLong}
                  size="1x"
                  color={theme.colors.primary.main}
                />
                Back
              </CreatePage.BackButton>
              <UrlMenu
                urls={[website.replace(/\/?$/, "/"), ...analyzedUrls].filter(
                  (value, index, self) => self.indexOf(value) === index
                )}
                setSelectedUrls={setSelectedUrls}
                selectedUrls={selectedUrls}
                website={website}
                customStyles={customStyles}
              />
            </>
          )}
        </>
      )}

      {confirmedDataSource === "google" && (
        <ThirdPartyUpload
          source={Integrations.Google}
          setConfirmedDataSource={setConfirmedDataSource}
          setCurrentStep={setCurrentStep}
          setSelectedUrls={setSelectedUrls}
          selectedUrls={selectedUrls}
          customStyles={customStyles}
        />
      )}
      {confirmedDataSource === "file-upload" && (
        <>
          <CreatePage.BackButton onClick={() => setConfirmedDataSource("")}>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faArrowLeftLong}
              size="1x"
              color={theme.colors.primary.main}
            />
            Back
          </CreatePage.BackButton>
          <FileUpload
            source={Integrations.Google}
            setCurrentStep={setCurrentStep}
            setSelectedUrls={setSelectedUrls}
            selectedUrls={selectedUrls}
            customStyles={customStyles}
          />
        </>
      )}
    </CreatePage.StepContainer>
  );
};

export default DataSelect;
