import styled from "styled-components";
import { Dialog } from "@mui/material";
import HomeBackground from "../../assets/images/homepage/home-bg.svg";

const WebpageStyles = {
  SectionContainer: styled.div<{$useBackground: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    background-image: ${props => props.$useBackground ? `url(${HomeBackground})` : 'none'} ;
    background-size: 100% 760px;
    background-position: top;
    background-repeat: no-repeat;
  `,
  LargeScreenBackground: styled.div`
    width: 100%;
    height: 760px;
    background-color: #fef3f0;
    position: absolute;
    top: 0;
    left: 0;
  `,
  LetsTalkContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 584px;

    @media (${(props) => props.theme.breakpoints.sm}) {
      height: fit-content;
      padding: 70px 0 100px 0;
      min-height: unset;
    }
    h1 {
      width: 80%;
      color: #20243a;
      font-size: 50px;
      font-weight: 500;
      line-height: 80px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 50px;
      @media (${(props) => props.theme.breakpoints.md}) {
        font-size: 29px;
        
      }
      @media (${(props) => props.theme.breakpoints.sm}) {
        margin-bottom: 76px;
        font-size: 50px;
        height: fit-content;
        line-height: normal;
      }
    }

    button {
      background-color: #7788ff;
      cursor: pointer;
      border: none;
      height: 81px;
      width: 328px;
      border-radius: 41px;
      color: white;
      font-size: 30px;
      font-weight: 500px;
      @media (${(props) => props.theme.breakpoints.sm}) {
        width: 285px;
        height: 78px;
      }
    }
  `,

  ReformDialog: styled(Dialog)`
    height: 100%;
    width: 100%;
    z-index: 900;
    & .MuiPaper-root {
      height: 900px;
      width: 80%;
      border-radius: 20px;
      margin: 0;
      @media (${(props) => props.theme.breakpoints.sm}) {
        height: 100%;
        width: 100vw;
        border-radius: 0;
      }
    }
    & .MuiDialogContent-root {
      padding: 0;
    }
    &:-webkit-scrollbar {
      display: none;
    }
  `,
};
export default WebpageStyles;
