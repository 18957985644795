import Layout from "./MainLayout";
import ClientProvider from "./context/ClientContext";
import Theme from "./theme";
import LibraryProvider from "./context/LibraryContext";
import NotificationProvider from "./context/NotificationContext";

function App() {
  return (
    <ClientProvider>
      <LibraryProvider>
        <NotificationProvider>
          <Theme>
            <Layout />
          </Theme>
        </NotificationProvider>
      </LibraryProvider>
    </ClientProvider>
  );
}

export default App;
