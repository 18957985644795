import FeedbackStyles from "./FeedbackStyles";
import { useMediaQuery, DialogContent } from "@mui/material";
import { useTheme } from "styled-components";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClient } from "../../context/ClientContext";

const Feedback = () => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);
  const { setFeedbackOpen } = useClient();
  const formEl: HTMLElement | null = document.getElementById("my-reform");
  const formElHtml: string | undefined = formEl?.innerHTML;

  return (
    <FeedbackStyles.Dialog 
    open={true}
    scroll="paper"
    >
      <FontAwesomeIcon
        onClick={() => setFeedbackOpen(false)}
        icon={faXmark}
        size="xl"
        style={{
          position: "absolute",
          top: "15px",
          right: "20px",
          zIndex: "3",
          cursor: "pointer",
        }}
      />
      <DialogContent>
      <iframe
            style={{ height: isSmallScreen ? "3405px" : "3262px", width: "100%" }}
            src={"https://forms.reform.app/9T1ZtL/feedback/PwKvOK"}
          />
      </DialogContent>
    </FeedbackStyles.Dialog>
  );
};

export default Feedback;
