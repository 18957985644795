import React, { useState, useRef, useEffect } from "react";
import UploaderStyles from "./UploaderStyles";
import { postMediaContent } from "../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faUpload,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

type Props = {
  setUploaderOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  property: string;
  setCustomStyles: React.Dispatch<
    React.SetStateAction<{
      logo: string;
      avatar: string;
      color: string;
    }>
  >;
};

const MediaUploader: React.FC<Props> = ({
  setUploaderOpen,
  open,
  property,
  setCustomStyles,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [fileObj, setFileObj] = useState<File>();
  const imageRef = useRef<HTMLImageElement>(null);
  const [croppedImageFile, setCroppedFile] = useState<File | null>(null);
  const [uploadURL, setUploadURL] = useState<string>("");
  const [crop, setCrop] = useState<Crop>();
  const [enabled, setEnabled] = useState(false);
  const uploadedRef = useRef(false);
  const imageTypeReg = /(jpe?g|png|gif)$/;
  const MAX_FILE_SIZE = 2097152;
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.dataTransfer.items) {
      if (!event.dataTransfer.items || !event.dataTransfer.items[0]) {
        setErrorMessage("No file detected");
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
        return;
      }
      if (event.dataTransfer.items[0].getAsFile()!.size > MAX_FILE_SIZE) {
        setErrorMessage("File is larger than 2MB, please use a smaller image");
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
        return;
      }
      if (!imageTypeReg.test(event.dataTransfer.items[0].getAsFile()!.type)) {
        setErrorMessage("Only .jpg .jpeg .png .gif files are allowed");
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
        return;
      }
      const img = new Image();
      const objectUrl = URL.createObjectURL(event.dataTransfer.files[0]);
      const droppedFile = event.dataTransfer.files[0];
      img.onload = () => {
        uploadedRef.current = true;
        setUploadURL(objectUrl);
        setFileObj(droppedFile);
      };
      img.src = objectUrl;
      setEnabled(true);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imageTypeReg = /(jpe?g|png)$/;

    if (!event.target.files || !event.target.files[0]) {
      setErrorMessage("No files detected");
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }
    if (event.target.files[0].size > MAX_FILE_SIZE) {
      setErrorMessage("File is larger than 2MB, please use a smaller image");
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }
    if (!imageTypeReg.test(event.target.files[0].type)) {
      setErrorMessage("Only .jpg, .jpeg,.png, .gif files are allowed");
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setUploadURL(objectUrl);
    setFileObj(event.target.files[0]);
    setEnabled(true);
  };

  useEffect(() => {
  }, [croppedImageFile]);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    const centeredCrop = centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          width: 60,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );

    setCrop(centeredCrop);
  }

  const confirmFile = () => {
    // set file to the correct property: logo or avatar
    postMediaContent({
      file: croppedImageFile ? croppedImageFile : (fileObj as File),
      tag: "",
    }).then((res: any) => {
      let fileURL = res.fileURL;
      setEnabled(true); // trying to change button state after upload. see line 317
      setCustomStyles((prev) => ({ ...prev, [property]: fileURL }));
      setUploaderOpen(false);
      setFileObj(undefined);
      setUploadURL("");
      setCroppedFile(null);
      setCrop(undefined);
      uploadedRef.current = false;
    });
  };

  const handleCropChange = (newCrop: Crop) => {
    setCrop(newCrop);
  };

  const onImageLoad = async (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    await centerAspectCrop(width, height, 1);
    if (!imageRef.current || !crop) return;
    const image = new Image();
    image.src = uploadURL;

    const canvas = document.createElement("canvas");
    canvas.width = crop.width;
    canvas.height = crop.height;

    const scaleFactor = image.width / imageRef.current.clientWidth;

    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.imageSmoothingQuality = "high";
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(
        image,
        crop.x * scaleFactor,
        crop.y * scaleFactor,
        crop.width * scaleFactor,
        crop.height * scaleFactor,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const croppedImageFile = new File([blob], "defaultCrop.png");
            setCroppedFile(croppedImageFile);
          }
        },
        "image/png",
        1
      );
    }
  };

  const handleCropComplete = (croppedAreaPixels: Crop) => {
    if (!imageRef.current) return;
    const image = new Image();
    image.src = uploadURL;

    const scaleFactor = image.width / imageRef.current.clientWidth;

    const canvas = document.createElement("canvas");
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.imageSmoothingQuality = "high";
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(
        image,
        croppedAreaPixels.x * scaleFactor,
        croppedAreaPixels.y * scaleFactor,
        croppedAreaPixels.width * scaleFactor,
        croppedAreaPixels.height * scaleFactor,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const croppedImageFile = new File([blob], "croppedImage.png");
            setCroppedFile(croppedImageFile);
          }
        },
        "image/png",
        1
      );
    }
  };

  return (
    <UploaderStyles.Container open={open} fullScreen={isSmallScreen}>
      <UploaderStyles.ModalCloseButton onClick={() => setUploaderOpen(false)}>
        <FontAwesomeIcon icon={faXmark} size="2x" />
      </UploaderStyles.ModalCloseButton>
      <UploaderStyles.UploadOverlay
        style={{ marginBottom: errorMessage ? "0" : "44px" }}
        onDrop={(e: any) => handleFileDrop(e)}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
      >
        {uploadURL && (
          <>
            <UploaderStyles.ImageCancel
              style={{ zIndex: "3" }}
              onClick={() => {
                setCrop(undefined);
                setUploadURL("");
                setCroppedFile(null);
                setFileObj(undefined);
                setEnabled(false);
                setErrorMessage("")
              }}
            >
              <FontAwesomeIcon icon={faXmark} style={{ width: "8px" }} />
            </UploaderStyles.ImageCancel>
            <ReactCrop
              circularCrop
              crop={crop}
              aspect={1}
              onChange={handleCropChange}
              onComplete={handleCropComplete}
            >
              <UploaderStyles.CustomIcon
                onLoad={onImageLoad}
                ref={imageRef}
                src={uploadURL}
                alt="custom icon"
              />
            </ReactCrop>
          </>
        )}
        {!uploadURL && (
          <>
            <>
              <FontAwesomeIcon
                icon={faUpload}
                style={{ marginBottom: "20px" }}
              />
              <p style={{ fontWeight: 500, marginBottom: "5px" }}>
                Drag and drop your image
              </p>
              <p style={{ marginBottom: "15px" }}>2 MB max file size</p>
            </>

            <UploaderStyles.BrowseButton>
              Browse files...
              <input
                accept="image/*"
                name="icon"
                type="file"
                hidden
                onChange={(e) => handleImageUpload(e)}
               
              />
            </UploaderStyles.BrowseButton>
          </>
        )}
      </UploaderStyles.UploadOverlay>
      {errorMessage && (
        <UploaderStyles.ErrorMessage>
          {errorMessage}
        </UploaderStyles.ErrorMessage>
      )}
      <UploaderStyles.Submitbutton
        $enabled={enabled}
        onClick={() => {
          if (!enabled) return;
          confirmFile();
        }}
      >
        Submit
        <FontAwesomeIcon icon={faCircleArrowRight} size="xl" />
      </UploaderStyles.Submitbutton>
    </UploaderStyles.Container>
  );
};

export default MediaUploader;
