import { useClient } from "../../context/ClientContext";
import { ChangeEvent, useEffect, useRef, useState } from "react";

const AddLocalFiles = ({
  opened,
  setOpened,
  importFiles,
}: {
  opened: "" | "website" | "google-drive" | "one-drive" | "file-upload";
  setOpened: React.Dispatch<
    React.SetStateAction<
      "" | "website" | "google-drive" | "one-drive" | "file-upload"
    >
  >;
  importFiles: () => Promise<void>;
}) => {
  const { selectedSources, setSelectedSources } = useClient();
  const allowedFileTypes = [
    "text/html",
    "application/atom+xml",
    "application/rss+xml",
    "text/markdown",
    "application/epub+zip",
    "text/xml",
    "application/xml",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
    "application/vnd.oasis.opendocument.text", // ODT
    "application/vnd.ms-excel", // XLS
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
    "text/csv",
    "text/tab-separated-values",
    "application/vnd.oasis.opendocument.spreadsheet", // ODS
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
    "application/vnd.oasis.opendocument.presentation", // ODP
    "application/vnd.oasis.opendocument.graphics", // ODG
    "application/javascript",
    "text/plain", // All text/* mime-types
    "application/pdf",
  ];
  const maxFileSize = 10485760; // 10MB in bytes

  useEffect(() => {
    if (opened === "file-upload") {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
    }
  }, [opened]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (opened === "file-upload") {
        setOpened("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [opened]);

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && (event.target.files[0] as File);
    // Do something with the selected file, such as uploading it
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (selectedFile) {
      if (!allowedFileTypes.includes(selectedFile.type)) {
        alert("Sorry! That file type is not supported.");
        return;
      }
      if (selectedFile.size > maxFileSize) {
        alert(
          "Sorry! the file size is too large. The file size can't exceed 10MB"
        );
        return;
      }
      setSelectedSources((prev) => {
        let fileType = "";
        if (selectedFile?.type.includes("pdf")) {
          fileType = "pdf";
        } else if (selectedFile?.type.includes("presentation")) {
          fileType = "slide";
        } else if (selectedFile?.type.includes("document")) {
          fileType = "doc";
        } else if (selectedFile?.type.includes("text")) {
          fileType = "text";
        } else if (selectedFile?.type.includes("folder")) {
          fileType = "folder";
        } else if (selectedFile?.type.includes("sheet")) {
          fileType = "sheet";
        }
        let obj = {
          name: selectedFile?.name as string,
          sourceType: "file-upload",

          sourceData: {
            lastEditedUtc: selectedFile?.lastModified as number,
            fileType,
            file: selectedFile,
          },
        };
        return [...prev, obj];
      });
    }
  };

  useEffect(() => {
    if (selectedSources.length > 0 && opened === "file-upload") {
      const importData = async () => {
        try {
          await importFiles();
        } catch (err) {
          alert(
            "Sorry! the file size is too large. The file size can't exceed 10MB"
          );
        }

        setSelectedSources([]);
        setOpened("");
      };

      importData();
    }
  }, [selectedSources, opened]);

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        accept={allowedFileTypes.join(",")}
      />
    </>
  );
};

export default AddLocalFiles;
