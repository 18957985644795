import { useState } from "react";
import SplashStyles from "../../pages/SplashPage/SplashStyles";
import WebpageStyles from "../../pages/Webpage/WebpageStyles";
import { LoginStyles } from "../Login/LoginStyles";
import { DialogContent } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useClient } from "../../context/ClientContext";
import ConfirmModal from "../BetaSignUp/ConfirmModal";

// Assets
import PoweredByXureal from "../../assets/images/PoweredByXureal.svg";
import LinkedIn from "../../assets/icons/socials/linkedin.svg";
import Instagram from "../../assets/icons/socials/instagram.svg";
import Discord from "../../assets/icons/socials/discord-white.svg";
import Reversed from "../../assets/images/branding/infobox/reversed.svg";

export const LoginFooter = () => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.md})`);
  const [isReformOpen, setReformOpen] = useState(false);
  const [textFieldVisible, setTextFieldVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [waitlistEmail, setWaitlistEmail] = useState("");
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { infoboxaiBETA } = useClient();

  const handleBetaSignUp = async () => {
    const signUpUser = () => {
      if (
        !waitlistEmail.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$")
      ) {
        setErrorMessage("Please enter a valid email");
        return;
      }
      const dataObj = {
        firstname: waitlistEmail.split("@")[0],
        lastname: waitlistEmail.split("@")[1],
        email: waitlistEmail,
        whitelisted: false,
        service: "infoboxai",
      };
      setConfirmOpen(true);

      infoboxaiBETA.postSubscriberData(dataObj).then((res: any) => {
        setWaitlistEmail("");
      });
    };
    infoboxaiBETA.getSubcriberByEmail(waitlistEmail).then((res: any) => {
      if (res.result === "FAILED") {
        signUpUser();
        setErrorMessage("");
      } else {
        setErrorMessage(
          "You are already waitlisted. We will contact you shortly!"
        );
      }
    });
  };

  const handleEmailChange = (newValue: string) => {
    setWaitlistEmail(newValue);
  };

  const handleLegalLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      {" "}
      <SplashStyles.HomeFooter>
        
        {isSmallScreen && (
          <SplashStyles.FlexColumn
            style={{
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <SplashStyles.GetStartedFooter>
              <img
                src={Reversed}
                alt=""
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
              <h3>Get started today</h3>
              <SplashStyles.ErrorMessage>
                {errorMessage}
              </SplashStyles.ErrorMessage>
            </SplashStyles.GetStartedFooter>
            {isSmallScreen && (
              <SplashStyles.FlexColumn
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: isSmallScreen ? "space-evenly" : "flex-start",
                  width: isSmallScreen ? "100%" : "auto",
                  marginBottom: "23px",
                }}
              >
                <SplashStyles.FooterColumn
                  style={{
                    height: "218px",
                    padding: "0",
                  }}
                >
                  <h3>OTHER SOLUTIONS</h3>
                  <a
                    href="https://xureal.com/metaverse"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>Metaverse</SplashStyles.TextLink>
                  </a>
                  <a
                    href="https://xureal.com/sales"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>
                      Sales Innovation
                    </SplashStyles.TextLink>
                  </a>
                  <a
                    href="https://xureal.com/training"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>
                      Immersive Training
                    </SplashStyles.TextLink>
                  </a>
                  <a
                    href="https://xureal.com/enterprise"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>Enterprise</SplashStyles.TextLink>
                  </a>
                  <a href="mailto: marketing@infobox.ai">
                    <SplashStyles.TextLink>Contact Us</SplashStyles.TextLink>
                  </a>
                </SplashStyles.FooterColumn>
                <SplashStyles.FooterColumn>
                  <h3>INFOBOX.AI</h3>
                  <a
                    href="https://infobox.ai/about/"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>About Infobox</SplashStyles.TextLink>
                  </a>
                  <a
                    href="https://xureal.com/about"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>Company</SplashStyles.TextLink>
                  </a>

                  <SplashStyles.TextLink onClick={() => setReformOpen(true)}>
                    Custom AI Solutions
                  </SplashStyles.TextLink>
                  <SplashStyles.TextLink
                    onClick={() =>
                      handleLegalLink(
                        "https://legal.infobox.ai/infoboxai-privacy-policy.html"
                      )
                    }
                  >
                    Privacy Policy
                  </SplashStyles.TextLink>
                  <SplashStyles.TextLink
                    onClick={() =>
                      handleLegalLink(
                        "https://legal.infobox.ai/infoboxai-terms-and-conditions.html"
                      )
                    }
                  >
                    Terms of Service
                  </SplashStyles.TextLink>
                </SplashStyles.FooterColumn>
              </SplashStyles.FlexColumn>
            )}
            {!isSmallScreen && (
              <SplashStyles.FlexRow
              $alignItems="flex-start"
              $justifyContent={isSmallScreen ? "space-evenly" : "flex-start"}
              $width={isSmallScreen ? "100%" : "auto"}
              $margin='0 auto'
              >
                <SplashStyles.FooterColumn>
                  <h3>INFOBOX.AI</h3>
                  <a
                    href="https://infobox.ai/about/"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>About Infobox</SplashStyles.TextLink>
                  </a>
                  <a
                    href="https://xureal.com/about"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>Company</SplashStyles.TextLink>
                  </a>

                  <SplashStyles.TextLink onClick={() => setReformOpen(true)}>
                    Custom AI Solutions
                  </SplashStyles.TextLink>

                  <a href="mailto: marketing@infobox.ai">
                    <SplashStyles.TextLink>Contact Us</SplashStyles.TextLink>
                  </a>
                </SplashStyles.FooterColumn>
                <SplashStyles.FooterColumn>
                  <h3>OTHER SOLUTIONS</h3>
                  <a
                    href="https://xureal.com/metaverse"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>Metaverse</SplashStyles.TextLink>
                  </a>
                  <a
                    href="https://xureal.com/sales"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>
                      Sales Innovation
                    </SplashStyles.TextLink>
                  </a>
                  <a
                    href="https://xureal.com/training"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>
                      Immersive Training
                    </SplashStyles.TextLink>
                  </a>
                  <a
                    href="https://xureal.com/enterprise"
                    target="_blank"
                  >
                    <SplashStyles.TextLink>Enterprise</SplashStyles.TextLink>
                  </a>
                </SplashStyles.FooterColumn>
                <SplashStyles.FooterColumn>
                  <h3>LEGAL</h3>
                  <a href=""></a>
                  <SplashStyles.TextLink
                    onClick={() =>
                      handleLegalLink(
                        "https://legal.infobox.ai/infoboxai-privacy-policy.html"
                      )
                    }
                  >
                    Privacy Policy
                  </SplashStyles.TextLink>
                  <SplashStyles.TextLink
                    onClick={() =>
                      handleLegalLink(
                        "https://legal.infobox.ai/infoboxai-terms-and-conditions.html"
                      )
                    }
                  >
                    Terms of Service
                  </SplashStyles.TextLink>
                </SplashStyles.FooterColumn>
              </SplashStyles.FlexRow>
            )}

            <SplashStyles.FlexColumn
              style={{
                justifyContent: "center",
                backgroundColor: "#6778E4",
                width: "100%",
              }}
            >
              <SplashStyles.FlexRow
                $margin={isSmallScreen ? "75px 0 63px" : "24px 0"}
                $flex="1"
                $justifyContent="flex-start"
              >
                <a
                  href="https://www.instagram.com/infobox.ai/"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk
                    src={Instagram}
                    alt="Instagram link"
                  />
                </a>

                <a
                  href="https://www.linkedin.com/company/xureal-metaverse"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk
                    src={LinkedIn}
                    alt="LinkedIn link"
                  />
                </a>
                <a
                  href="https://discord.gg/W2YsUjMX"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk
                    style={{ marginRight: "0" }}
                    src={Discord}
                    alt="Discord link"
                  />
                </a>
              </SplashStyles.FlexRow>
              <SplashStyles.PoweredBy>
                <p>Powered by</p>
                <img
                  src={PoweredByXureal}
                  alt="Powered by Xureal"
                />
              </SplashStyles.PoweredBy>
              <SplashStyles.Copyright>
                <SplashStyles.LegalFooter
                  style={{ marginRight: isSmallScreen ? "0" : "4vw" }}
                >
                  &copy; 2023 All Rights Reserved. Xureal &reg;
                </SplashStyles.LegalFooter>
              </SplashStyles.Copyright>
            </SplashStyles.FlexColumn>
          </SplashStyles.FlexColumn>
        )}
        {!isSmallScreen && (
          <>
            <SplashStyles.FlexRow
              $flex="1"
              $justifyContent="space-between"
              $margin="auto"
              $width="100%"
              style={{
                padding: "0 126px 0 126px",
                maxWidth: '1440px'
              }}
            >
              <SplashStyles.GetStartedFooter>
                <img
                  src={Reversed}
                  alt=""
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                />
                <h3>Get started today</h3>
               

              </SplashStyles.GetStartedFooter>
              <SplashStyles.FooterColumn>
                <h3>INFOBOX.AI</h3>
                <a
                  href="https://infobox.ai/about/"
                  target="_blank"
                >
                  <SplashStyles.TextLink>About Infobox</SplashStyles.TextLink>
                </a>
                <a
                  href="https://xureal.com/about"
                  target="_blank"
                >
                  <SplashStyles.TextLink>Company</SplashStyles.TextLink>
                </a>

                <SplashStyles.TextLink onClick={() => setReformOpen(true)}>
                  Custom AI Solutions
                </SplashStyles.TextLink>

                <a href="mailto: marketing@infobox.ai">
                  <SplashStyles.TextLink>Contact Us</SplashStyles.TextLink>
                </a>
              </SplashStyles.FooterColumn>
              <SplashStyles.FooterColumn>
                <h3>OTHER SOLUTIONS</h3>
                <a
                  href="https://xureal.com/metaverse"
                  target="_blank"
                >
                  <SplashStyles.TextLink>Metaverse</SplashStyles.TextLink>
                </a>
                <a
                  href="https://xureal.com/sales"
                  target="_blank"
                >
                  <SplashStyles.TextLink>
                    Sales Innovation
                  </SplashStyles.TextLink>
                </a>
                <a
                  href="https://xureal.com/training"
                  target="_blank"
                >
                  <SplashStyles.TextLink>
                    Immersive Training
                  </SplashStyles.TextLink>
                </a>
                <a
                  href="https://xureal.com/enterprise"
                  target="_blank"
                >
                  <SplashStyles.TextLink>Enterprise</SplashStyles.TextLink>
                </a>
              </SplashStyles.FooterColumn>
              <SplashStyles.FooterColumn
              style={{
                marginTop: '-100px'
              }}>
                <h3>LEGAL</h3>
                <SplashStyles.TextLink
                  onClick={() =>
                    handleLegalLink(
                      "https://legal.infobox.ai/infoboxai-privacy-policy.html"
                    )
                  }
                >
                  Privacy Policy
                </SplashStyles.TextLink>
                <SplashStyles.TextLink
                  onClick={() =>
                    handleLegalLink(
                      "https://legal.infobox.ai/infoboxai-terms-and-conditions.html"
                    )
                  }
                >
                  Terms of Service
                </SplashStyles.TextLink>
              </SplashStyles.FooterColumn>
            </SplashStyles.FlexRow>
            <div style={{width: '100%', backgroundColor: "#6778E4"}}>
            <SplashStyles.FlexRow
              $height="124px"
              $width="100%"
              $justifyContent="space-between"
              $margin="auto"
              style={{
                width: "100%",
                padding: "0 133px 0 133px",
                maxWidth: '1440px'
              }}
            >
              <SplashStyles.FlexRow
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  maxWidth: "248px",
                }}
              >
                <a
                  href="https://www.instagram.com/infobox.ai/"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk
                    src={Instagram}
                    alt="Instagram link"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/xureal-metaverse"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk
                    src={LinkedIn}
                    alt="LinkedIn link"
                  />
                </a>
                <a
                  href="https://discord.gg/W2YsUjMX"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk
                    src={Discord}
                    alt="Discord link"
                  />
                </a>
              </SplashStyles.FlexRow>
              <SplashStyles.LoveMessage>
                MADE WITH ❤️ IN ORLANDO
              </SplashStyles.LoveMessage>

              <SplashStyles.PoweredBy>
                <p>Powered by</p>
                <img
                  src={PoweredByXureal}
                  alt="Powered by Xureal"
                />
              </SplashStyles.PoweredBy>
            </SplashStyles.FlexRow>
            </div>
            <SplashStyles.Copyright>
              <SplashStyles.LegalFooter
                style={{ marginRight: isSmallScreen ? "0" : "4vw" }}
              >
                &copy; 2023 All Rights Reserved. Xureal &reg;
              </SplashStyles.LegalFooter>
            </SplashStyles.Copyright>
          </>
        )}
        <div style={{ width: "169px" }} />
      </SplashStyles.HomeFooter>
      {/* Reform Dialog window */}
      <WebpageStyles.ReformDialog
        fullScreen={isSmallScreen}
        scroll="paper"
        open={isReformOpen}
      >
        <LoginStyles.CloseButton onClick={() => setReformOpen(false)}>
          <FontAwesomeIcon icon={faXmark} />
        </LoginStyles.CloseButton>
        <DialogContent>
          <iframe
            style={{ height: "900px", width: "100%" }}
            src={"https://forms.reform.app/9T1ZtL/feature-requests/uvdI5S"}
          />
        </DialogContent>
      </WebpageStyles.ReformDialog>
      {isConfirmOpen && (
        <ConfirmModal
          setConfirmOpen={setConfirmOpen}
          isOpen={isConfirmOpen}
        />
      )}
    </div>
  );
};

export default LoginFooter;