import { useEffect, useState } from "react";
import { useClient } from "../context/ClientContext";

const GoogleDriveRedirect = () => {
  const [message, setMessage] = useState("");
  const { setGoogleDriveConnected, googleDriveConnected } = useClient();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of the 'code' parameter
    const result = urlParams.get("result");
    if (result === "SUCCESS") {
      // close tab
      setMessage("Google Drive Connected to account");
      setGoogleDriveConnected(true);
      window.opener.postMessage(JSON.stringify({ result }), "*");
      window.close();
    } else {
      setMessage("Error connecting google drive");
      setGoogleDriveConnected(false);
    }
  }, []);

  useEffect(() => {
    console.log(googleDriveConnected);
  }, [googleDriveConnected]);
  return <>{message}</>;
};

export default GoogleDriveRedirect;
