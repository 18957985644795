import React from "react";
import BetaStyles from "./BetaStyles";
import CurveBG from '../../assets/images/homepage/curve-top.svg';
import ColorBG from '../../assets/images/homepage/modal-bg.png';
import Logo from '../../assets/images/branding/infobox/logoIcon.png';
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

type Props = {
    isOpen: boolean;
    setConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmModal: React.FC<Props> = ({isOpen, setConfirmOpen}) => {
    const { ConfirmModal } = BetaStyles;
    return(
        <ConfirmModal.Container open={isOpen}>
            <ConfirmModal.InnerContainer>
                <ConfirmModal.LogoContainer>
                    <ConfirmModal.Logo src={Logo} alt='Infobox logo' />
                    <ConfirmModal.Icon icon={faCircleCheck} color="#23CD8F" />
                </ConfirmModal.LogoContainer>
<ConfirmModal.CopyContainer>

                <ConfirmModal.Title>We’ve added you to our waitlist!</ConfirmModal.Title>
                <ConfirmModal.Subtitle>We will contact you shortly.</ConfirmModal.Subtitle>
                <BetaStyles.SubmitButton onClick={() => setConfirmOpen(false)}>Close</BetaStyles.SubmitButton>
</ConfirmModal.CopyContainer>
                <ConfirmModal.ColorBG src={ColorBG} alt='' />
                <ConfirmModal.CurveBG src={CurveBG} alt='' />
            </ConfirmModal.InnerContainer>
            <ConfirmModal.CoverBG></ConfirmModal.CoverBG>
        </ConfirmModal.Container>
    )
}

export default ConfirmModal;