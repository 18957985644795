import styled from 'styled-components';

const PreviewStyles = {
    PageContainer: styled.div`
        height: calc(100% - 62px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding: 24px;
        background-color: #eeebe7;

        @media ${props => props.theme.breakpoints.md} {
            padding: 0;
        }
    `,
    Iframe: styled.iframe`
        
        height: 663px;
        border-radius: 30px;
        border: none;
        width: 100%;
        max-width: 1192.05px;
        object-fit: contain;
        @media (max-width: 1245px) {
            min-height: 675px;
            aspect-ratio: 16 / 9;
            flex: unset;
        }
        @media ${props => props.theme.breakpoints.md} {
            max-height: 400px;
            max-width: 500px;
            display: none;
        }
    `,
}

export default PreviewStyles;