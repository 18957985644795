import React, { useEffect, useState } from "react";
import LayoutStyles from "../App.styles";
import { useClient } from "../context/ClientContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faThumbTack,
  faCheck,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import InfoBoxLogo from "../assets/images/infoboxLogo.png";
import DataSelect from "../components/DataUpload";
import Customizer from "../components/OnboardCustomizer/Customizer";
import { useTheme } from "styled-components";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CreatePage = () => {
  const { CreatePage } = LayoutStyles;
  const [currentStep, setCurrentStep] = useState(1);
  const theme: any = useTheme();
  const navigate = useNavigate();
  const useMobileLayout = useMediaQuery(theme.breakpoints.sm);
  const [selectedUrls, setSelectedUrls] = useState<
    {
      name: string;
      sourceType: string;
      sourceData: { [key: string]: any };
    }[]
  >([]);
  const [nameFieldValue, setNameFieldValue] = useState("");
  const { setCompanyName, currentUser } = useClient();
  const [customStyles, setCustomStyles] = useState({
    logo: "",
    avatar: "",
    color: "#20243A",
  });

  const creationSteps = [
    "Name your Assistant",
    "Customize your Assistant",
    "Upload files to your knowledge library",
  ];

  const mobileSteps = [
    "Name your Assistant",
    "Customize your Assistant",
    "Add files",
  ];

  useEffect(() => {
    if (currentUser.onboarded) {
      navigate("/data");
    }
  }, [currentUser]);

  if (!currentUser.email) {
    return (
      <CreatePage.StepContainer>
        <CreatePage.Heading1>Loading...</CreatePage.Heading1>
      </CreatePage.StepContainer>
    );
  }

  return (
    <CreatePage.Container>
      <CreatePage.StepProgressSidebar>
        <CreatePage.Logo src={InfoBoxLogo} alt="Infobox" />
        <div style={{ width: "100%" }}>
          {!useMobileLayout && (
            <CreatePage.Heading2>
              Let's create your Knowledge Assistant
            </CreatePage.Heading2>
          )}
          <CreatePage.StepTrack>
            {(useMobileLayout ? mobileSteps : creationSteps).map(
              (step, index) => {
                return (
                  <React.Fragment key={`step-${index}`}>
                    <CreatePage.StepRow>
                      <CreatePage.StepNumber
                        $complete={currentStep > index + 1}
                        $active={index + 1 === currentStep}
                      >
                        {currentStep > index + 1 ? (
                          <FontAwesomeIcon icon={faCheck} color="#fff" />
                        ) : (
                          index + 1
                        )}
                      </CreatePage.StepNumber>
                      <CreatePage.Step $active={index + 1 === currentStep}>
                        {step}
                      </CreatePage.Step>
                    </CreatePage.StepRow>
                    {/* The below is the dotted line between step numbers */}
                    {!useMobileLayout && index !== creationSteps.length - 1 && (
                      <div
                        style={{
                          borderLeft: "1px dotted #DBD0C9",
                          height: "51px",
                          transform: "translateX(14.5px)",
                        }}
                      />
                    )}
                  </React.Fragment>
                );
              }
            )}
          </CreatePage.StepTrack>
        </div>
        {/* This div equally spaces all containers within the side bar based on space-between */}
        <div style={{ height: "37px" }}></div>
      </CreatePage.StepProgressSidebar>
      {currentStep === 1 && (
        <>
          <CreatePage.StepContainer>
            <CreatePage.Heading1>Name your Assistant</CreatePage.Heading1>
            <LayoutStyles.FlexRow $margin="0 0 49px 0">
              {!useMobileLayout && (
                <FontAwesomeIcon
                  icon={faThumbTack}
                  color={theme.colors.primary.main}
                  style={{ marginRight: "17px" }}
                />
              )}
              <CreatePage.Subtext>
                We recommend using your company name if you’re a business.
              </CreatePage.Subtext>
            </LayoutStyles.FlexRow>
            <LayoutStyles.LargeTextField
              placeholder="Type name"
              value={nameFieldValue ? nameFieldValue : ""}
              onChange={(e) => setNameFieldValue(e.target.value)}
              inputProps={{
                maxLength: 30,
              }}
              InputProps={{
                endAdornment: (
                  <LayoutStyles.LargeTextFieldButton
                    $enabled={!!nameFieldValue}
                    disabled={!nameFieldValue}
                    onClick={() => {
                      setCompanyName(nameFieldValue);
                      setCurrentStep(2);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRightLong}
                      color="#FFF6F0"
                      size={useMobileLayout ? "xl" : "2x"}
                    />
                  </LayoutStyles.LargeTextFieldButton>
                ),
              }}
            />
          </CreatePage.StepContainer>
        </>
      )}

      {currentStep === 3 && (
        <DataSelect
          selectedUrls={selectedUrls}
          setSelectedUrls={setSelectedUrls}
          setCurrentStep={setCurrentStep}
          customStyles={customStyles}
        />
      )}

      {currentStep === 2 && (
        <CreatePage.StepContainer>
          <CreatePage.BackButton onClick={() => setCurrentStep(1)}>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faArrowLeftLong}
              size="1x"
              color={theme.colors.primary.main}
            />
            Back
          </CreatePage.BackButton>
          <CreatePage.Heading2
            style={{ marginBottom: "10px", marginTop: "25px" }}
          >
            Customize your Assistant
          </CreatePage.Heading2>
          <LayoutStyles.FlexRow $margin="10px">
            {!useMobileLayout && (
              <FontAwesomeIcon
                icon={faThumbTack}
                color={theme.colors.primary.main}
                style={{ marginRight: "17px" }}
              />
            )}
            <CreatePage.Subtext>
              Customize the appearance of your Assistant with your logo and
              colors.
            </CreatePage.Subtext>
          </LayoutStyles.FlexRow>
          <Customizer
            customStyles={customStyles}
            setCustomStyles={setCustomStyles}
            setCurrentStep={setCurrentStep}
            selectedUrls={selectedUrls}
          />
        </CreatePage.StepContainer>
      )}
    </CreatePage.Container>
  );
};

export default CreatePage;
