import { useState } from "react";
import LayoutStyles from "../../App.styles";
import ModalStyles from "./ModalStyles";
import { customizeChatbot } from "../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLibrary } from "../../context/LibraryContext";
import {
  faArrowCircleUp,
  faBrush,
  faPaperPlane,
  faArrowCircleRight,
  faThumbTack,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import DefaultLogo from "../../assets/images/default-logo.png";
import { ChromePicker, ColorResult } from "react-color";
import { useClient } from "../../context/ClientContext";
import ModalMediaUploader from "./ModalMediaUploader";
import { useTheme } from "styled-components";
import { useMediaQuery, Dialog } from "@mui/material";
import { ChatbotType } from "../../types/AuthTypes";
import { useNotifications } from "../../context/NotificationContext";
import { NotificationDataTypes } from "../../types/NotificationTypes";
import { toastMessages } from "../../utils/toastMessages";

const CustomizerModal = () => {
  const { OBCustomizer, CreatePage } = LayoutStyles;
  const [colorOpen, setColorOpen] = useState(false);
  const { setCustomOpen } = useLibrary();
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [previewTextColor, setPreviewText] = useState("#ffffff");
  const { currentChatbot, setCurrentChatbot } = useClient();
  const [currentImgProp, setImgProp] = useState("");
  const theme: any = useTheme();
  const useMobileLayout = useMediaQuery(theme.breakpoints.sm);
  const { dispatch } = useNotifications();
  const [customStyles, setCustomStyles] = useState({
    logo: currentChatbot.widget_logo,
    avatar: currentChatbot.widget_avatar,
    color: currentChatbot.widget_color,
  });

  const handleColorSelect = (color: ColorResult) => {
    setCustomStyles((prev) => ({ ...prev, color: color.hex }));
    setPreviewText(getTextColor(color.hex));
  };

  const getTextColor = (backgroundColor: string) => {
    const color = backgroundColor.substring(1);
    // Example logic for determining contrasting text color based on background color
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness >= 180 ? "#000000" : "#ffffff";
  };

  const submitCustomChange = () => {
    customizeChatbot({
      chatbotID: currentChatbot.chatbotID,
      widget_avatar: customStyles.avatar,
      widget_color: customStyles.color,
      widget_logo: customStyles.logo,
    })
      .then((res: { result: string; data: ChatbotType }) => {
        if (res.result === "SUCCESS") {
          setCurrentChatbot(res.data);
          setCustomOpen(false);
          const { message, status } = toastMessages('STYLE_UPDATED');
          dispatch({
            type: NotificationDataTypes.SET_TOAST,
            payload: {
              open: true,
              message: message,
              status: status
            }
          })
        }
      })
      .catch((err: any) => {
        console.log(err);
        setCustomOpen(false);
        const { message, status } = toastMessages('STYLE_UPDATE_FAILED');
          dispatch({
            type: NotificationDataTypes.SET_TOAST,
            payload: {
              open: true,
              message: message,
              status: status
            }
          })
      });
  };

  return (
    <Dialog
      fullScreen={useMobileLayout}
      open={true}
      scroll="paper"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: useMobileLayout ? "0" : "34px",
          padding: useMobileLayout ? "25px 40px" : "50px 80px",
          width: "919px",
          maxHeight: useMobileLayout ?  "unset" : "700px",
          maxWidth: useMobileLayout ? "919px" : "919px",

        },
        
      }}
    >
      <ModalStyles.CloseButton onClick={() => setCustomOpen(false)}>
        <FontAwesomeIcon icon={faXmark} size="2x" />
      </ModalStyles.CloseButton>
      <ModalStyles.Container>
        <CreatePage.Heading2 style={{ marginBottom: "16px" }}>
          Customization
        </CreatePage.Heading2>
        <LayoutStyles.FlexRow $margin={useMobileLayout ? "0" : "0 0 49px 0"}>
          {!useMobileLayout && (
            <FontAwesomeIcon
              icon={faThumbTack}
              color={theme.colors.primary.main}
              style={{ marginRight: "17px" }}
            />
          )}
          <CreatePage.Subtext>
            Customize the appearance of your knowledge assisant with your logo
            and colors.
          </CreatePage.Subtext>
        </LayoutStyles.FlexRow>
        <OBCustomizer.Container>
          <OBCustomizer.Preview>
            <OBCustomizer.PreviewBG>
              <OBCustomizer.ChatBox>
                <OBCustomizer.TopBar $bgColor={customStyles.color}>
                  <OBCustomizer.ImageContainer
                    $size="39"
                    $bgImage={
                      customStyles.logo
                        ? customStyles.logo
                        : currentChatbot.widget_logo
                        ? currentChatbot.widget_logo
                        : DefaultLogo
                    }
                  />
                  <OBCustomizer.ChatTitle $color={previewTextColor}>
                    {currentChatbot.assistant_name
                      ? currentChatbot.assistant_name
                      : "Test company name"}
                  </OBCustomizer.ChatTitle>
                </OBCustomizer.TopBar>
                <OBCustomizer.ChatSection>
                  <OBCustomizer.ChatBubble>
                    {`Type a message to begin your conversation with ${currentChatbot.assistant_name}.`}
                    <OBCustomizer.ExampleAvatar
                      $bgImage={
                        customStyles.avatar ? customStyles.avatar : DefaultLogo
                      }
                    />
                  </OBCustomizer.ChatBubble>
                  <LayoutStyles.FlexRow>
                    <div
                      style={{
                        flex: "1",
                        height: useMobileLayout ? "35px" : "41px",
                        borderRadius: "10px",
                        backgroundColor: "#F3F4F9",
                      }}
                    />
                    <OBCustomizer.SendButton $bgColor={customStyles.color}>
                      <FontAwesomeIcon
                        size="lg"
                        icon={faPaperPlane}
                        color={previewTextColor}
                      />
                    </OBCustomizer.SendButton>
                  </LayoutStyles.FlexRow>
                </OBCustomizer.ChatSection>
              </OBCustomizer.ChatBox>
              <OBCustomizer.ChatButton
                $bgImage={customStyles.logo ? customStyles.logo : DefaultLogo}
              />
            </OBCustomizer.PreviewBG>
          </OBCustomizer.Preview>
          <OBCustomizer.OptionContainer>
            <OBCustomizer.OptionBox
              onClick={() => {
                setImgProp("logo");
                setUploaderOpen(true);
              }}
            >
              <OBCustomizer.ImageContainer
                $bgImage={customStyles.logo ? customStyles.logo : DefaultLogo}
              />
              <OBCustomizer.Label>Assistant logo</OBCustomizer.Label>
              <OBCustomizer.IconContainer>
                <FontAwesomeIcon
                  icon={faArrowCircleUp}
                  size="lg"
                  color="#fff"
                />
              </OBCustomizer.IconContainer>
            </OBCustomizer.OptionBox>
            <OBCustomizer.OptionBox
              onClick={() => {
                setImgProp("avatar");
                setUploaderOpen(true);
              }}
            >
              <OBCustomizer.ImageContainer
                $bgImage={
                  customStyles.avatar ? customStyles.avatar : DefaultLogo
                }
              />
              <OBCustomizer.Label>Assistant avatar</OBCustomizer.Label>
              <OBCustomizer.IconContainer>
                <FontAwesomeIcon
                  icon={faArrowCircleUp}
                  size="lg"
                  color="#fff"
                />
              </OBCustomizer.IconContainer>
            </OBCustomizer.OptionBox>
            <OBCustomizer.OptionBox onClick={() => setColorOpen(true)}>
              <div style={{ width: "50px" }}>
                <OBCustomizer.CurrentColor $bgColor={customStyles.color} />
              </div>
              <OBCustomizer.Label>
                Assistant color
                <OBCustomizer.SubText>
                  {customStyles.color}
                </OBCustomizer.SubText>
              </OBCustomizer.Label>
              <OBCustomizer.IconContainer>
                <FontAwesomeIcon icon={faBrush} size="lg" color="#fff" />
              </OBCustomizer.IconContainer>
              {colorOpen && (
                <div style={{ position: "absolute", right: "0", top: "0" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      bottom: "0",
                      right: "0",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setColorOpen(false);
                    }}
                  />
                  <ChromePicker
                    color={customStyles.color}
                    onChangeComplete={handleColorSelect}
                    disableAlpha
                  />
                </div>
              )}
            </OBCustomizer.OptionBox>
          </OBCustomizer.OptionContainer>
        </OBCustomizer.Container>

        <LayoutStyles.CreatePage.ContinueButton
          onClick={() => submitCustomChange()}
          $enabled={true}
        >
          Continue
          <FontAwesomeIcon size="xl" icon={faArrowCircleRight} />
        </LayoutStyles.CreatePage.ContinueButton>

        <ModalMediaUploader
          open={uploaderOpen}
          setUploaderOpen={setUploaderOpen}
          setCustomStyles={setCustomStyles}
          property={currentImgProp}
        />
      </ModalStyles.Container>
    </Dialog>
  );
};

export default CustomizerModal;
