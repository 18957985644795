import React, { useState, useRef, ChangeEvent } from "react";
import LayoutStyles from "../../App.styles";

import { Integrations } from "../../context/LibraryContext";
import DocCard from "../Cards/DocCard";
import { useClient } from "../../context/ClientContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faCircleArrowUp,
  faUpFromLine,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { CustomStyles } from "../OnboardCustomizer/Customizer";
import { useNavigate } from "react-router-dom";

type Props = {
  source: Integrations;
  selectedUrls: {
    name: string;
    sourceType: string;
    sourceData: { [key: string]: any };
    active?: boolean;
  }[];
  setSelectedUrls: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        sourceType: string;
        sourceData: { [key: string]: any };
        active?: boolean;
      }[]
    >
  >;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  customStyles: CustomStyles;
};

const FileUpload: React.FC<Props> = ({
  source,
  setSelectedUrls,
  selectedUrls,
  setCurrentStep,
  customStyles,
}) => {
  const { CreatePage } = LayoutStyles;
  const uploadedFiles = ["", ""];
  const { initiateBotCreation, companyName } = useClient();
  const [hoverIdx, setHoverIdx] = useState(-1);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isProcessing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const allowedFileTypes = [
    "text/html",
    "application/atom+xml",
    "application/rss+xml",
    "text/markdown",
    "application/epub+zip",
    "text/xml",
    "application/xml",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
    "application/vnd.oasis.opendocument.text", // ODT
    "application/vnd.ms-excel", // XLS
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
    "text/csv",
    "text/tab-separated-values",
    "application/vnd.oasis.opendocument.spreadsheet", // ODS
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
    "application/vnd.oasis.opendocument.presentation", // ODP
    "application/vnd.oasis.opendocument.graphics", // ODG
    "application/javascript",
    "text/plain", // All text/* mime-types
    "application/pdf",
  ];
  const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

  const handleUploadButtonClick = () => {
    // Trigger the file input click event
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && (event.target.files[0] as File);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    // Do something with the selected file, such as uploading it
    if (!selectedFile) return;
    if (!allowedFileTypes.includes(selectedFile.type)) {
      alert("Sorry! That file type is not supported.");
      return;
    }
    if (selectedFile.size > maxFileSize) {
      alert(
        "Sorry! the file size is too large. The file size can't exceed 10MB"
      );
      return;
    }

    setSelectedUrls((prev) => {
      let fileType = "";
      if (selectedFile?.type.includes("pdf")) {
        fileType = "pdf";
      } else if (selectedFile?.type.includes("presentation")) {
        fileType = "slide";
      } else if (selectedFile?.type.includes("document")) {
        fileType = "doc";
      } else if (selectedFile?.type.includes("text")) {
        fileType = "text";
      } else if (selectedFile?.type.includes("folder")) {
        fileType = "folder";
      } else if (selectedFile?.type.includes("sheet")) {
        fileType = "sheet";
      }
      let obj = {
        name: selectedFile?.name as string,
        sourceType: "file-upload",

        sourceData: {
          lastEditedUtc: selectedFile?.lastModified as number,
          fileType,
          file: selectedFile,
        },
      };
      return [...prev, obj];
    });
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        accept={allowedFileTypes.join(",")}
      />
      <LayoutStyles.LibrarySection.TitleSection style={{ marginBottom: 60 }}>
        <LayoutStyles.LibrarySection.LibraryTitle style={{ fontSize: 20 }}>
          <FontAwesomeIcon
            icon={faUpFromLine}
            style={{ height: 23, marginRight: 13, color: "#7689FF" }}
          />
          Uploads
        </LayoutStyles.LibrarySection.LibraryTitle>
        <LayoutStyles.LibrarySection.UploadButton
          onClick={handleUploadButtonClick}
        >
          <FontAwesomeIcon icon={faCircleArrowUp} style={{ height: 18 }} />
          Upload files
        </LayoutStyles.LibrarySection.UploadButton>
      </LayoutStyles.LibrarySection.TitleSection>
      {uploadedFiles.length > 0 && (
        <CreatePage.DocumentGrid
          container
          spacing="20px"
          justifyContent="center"
          alignItems="center"
          width="90%"
          maxWidth="1300px"
          padding="0 0 50px 20px"
        >
          {selectedUrls
            .filter((source) => source.sourceType === "file-upload")
            .map((source, index) => (
              <LayoutStyles.CreatePage.GridItem
              item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                key={index}
              >
                <div
                  style={{ position: "relative", width: '100%' }}
                  onMouseOver={() => setHoverIdx(index)}
                  onMouseOut={() => setHoverIdx(-1)}
                  
                >
                  {index === hoverIdx && (
                    <LayoutStyles.CreatePage.DeleteButton
                      onClick={() => {
                        setSelectedUrls((prev) =>
                          prev.filter((_, idx) => idx !== index)
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </LayoutStyles.CreatePage.DeleteButton>
                  )}
                  <DocCard
                    maxWidth={"unset"}
                    name={source.name}
                    lastEdited={source.sourceData?.lastEditedUtc as number}
                    fileType={source.sourceData.fileType}
                    uploaded={true}
                    synced={true}
                    active={true}
                  />
                </div>
              </LayoutStyles.CreatePage.GridItem>
            ))}
        </CreatePage.DocumentGrid>
      )}
      <LayoutStyles.CreatePage.ContinueButton
        onClick={async () => {
          if (selectedUrls.length > 0) {
            if (isProcessing) return;
            setProcessing(true);

            await initiateBotCreation(
              selectedUrls,
              { ...customStyles, name: companyName },
              () => {}
            );
            navigate("/data");
          } else {
            console.log("select at least one option");
          }
        }}
        $enabled={!!(selectedUrls.length > 0)}
      >
        Continue
        <FontAwesomeIcon size="xl" icon={faArrowCircleRight} />
      </LayoutStyles.CreatePage.ContinueButton>
    </>
  );
};

export default FileUpload;
