import React, { useEffect, useState, useRef } from "react";
import { getUserGoogleDrive } from "../../actions";
import LayoutStyles from "../../App.styles";
import useDrivePicker from "react-google-drive-picker";
import { useNavigate } from "react-router-dom";
import { CustomStyles } from "../OnboardCustomizer/Customizer";
import { useTheme } from "styled-components";

import { Integrations, intDisplayData } from "../../context/LibraryContext";
import DocCard from "../Cards/DocCard";
import { useClient } from "../../context/ClientContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faCircleArrowUp,
  faXmark,
  faArrowLeftLong,
} from "@fortawesome/pro-solid-svg-icons";
declare let google: any;

type Props = {
  source: Integrations;
  selectedUrls: {
    name: string;
    sourceType: string;
    sourceData: { [key: string]: any };
  }[];
  setSelectedUrls: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        sourceType: string;
        sourceData: { [key: string]: any };
      }[]
    >
  >;
  setConfirmedDataSource: React.Dispatch<React.SetStateAction<string>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  customStyles: CustomStyles;
};

const ThirdPartyUpload: React.FC<Props> = ({
  source,
  setSelectedUrls,
  selectedUrls,
  setConfirmedDataSource,
  customStyles,
}) => {
  const { CreatePage } = LayoutStyles;
  const uploadedFiles = ["", ""];
  const [openPicker, authResponse] = useDrivePicker();
  const [token, setToken] = useState("");
  const theme: any = useTheme();
  const { initiateBotCreation, setGoogleDriveConnected, companyName } =
    useClient();
  const [hoverIdx, setHoverIdx] = useState(-1);
  const [opened, setOpened] = useState("");
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isProcessing, setProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (opened === "google-drive") {
        setOpened("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (opened === "google-drive")
      getUserGoogleDrive()
        .then((res: any) => {
          if (res.result === "SUCCESS") {
            setToken(res.token);
          }
        })
        .catch((err) => {
          console.log(err);
          const popupWindow = window.open(
            `${process.env.REACT_APP_BASE_URL}/google-drive-auth`,
            "_blank",
            "width=500,height=600,toolbar=no"
          );

          const handleMessage = (event: MessageEvent<any>) => {
            const message = event.data;
            if (message && event.source === popupWindow) {
              let res = JSON.parse(message);
              if (res.result === "SUCCESS") {
                setGoogleDriveConnected(true);
                getDriveToken();
              } else {
                setGoogleDriveConnected(false);
              }
            }
          };
          window.addEventListener("message", handleMessage);
        });
  }, [opened]);

  const getDriveToken = () => {
    getUserGoogleDrive().then((res: any) => {
      if (res.result === "SUCCESS") {
        setToken(res.token);
      }
    });
  };

  const mimeTypes = [
    "application/vnd.google-apps.presentation",
    "application/vnd.google-apps.document",
    "application/vnd.google-apps.presentation",
    "application/vnd.google-apps.file",
    "application/msword",
    "text/plain",
    "text/vtt",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.google-apps.spreadsheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "text/tab-separated-values",
  ];

  const openGooglePicker = () => {
    const customViews = [
      new google.picker.DocsView()
        .setIncludeFolders(true)
        .setMode(google.picker.DocsViewMode.LIST)
        .setMimeTypes(mimeTypes.reduce((a, b) => a + "," + b)),
    ];
    openPicker({
      clientId:
        "499706963417-t5qaqd6tbrup95oq22ef45ec70j38tou.apps.googleusercontent.com",
      developerKey: "AIzaSyAKpHq2je-sWn6pNPJbBdWeBcnIQ93Qgi8",
      viewId: "DOCS",
      appId: "499706963417",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      setIncludeFolders: true,
      disableDefaultView: true,
      customViews: customViews,
      setSelectFolderEnabled: false,
      viewMimeTypes: mimeTypes.reduce((a, b) => a + "," + b),
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          setOpened("");
        }
        if (Array.isArray(data.docs)) {
          setSelectedUrls((prev) => {
            let objects = [...data.docs];
            const idTracker: { [id: string]: boolean } = {};
            const result = [];
            for (let i = 0; i < objects.length; i++) {
              const currentObject = objects[i];
              const id = currentObject.id;
              let fileType = "";
              if (currentObject.mimeType.includes("pdf")) {
                fileType = "pdf";
              } else if (currentObject.mimeType.includes("presentation")) {
                fileType = "slide";
              } else if (currentObject.mimeType.includes("document")) {
                fileType = "doc";
              } else if (currentObject.mimeType.includes("text")) {
                fileType = "text";
              } else if (currentObject.mimeType.includes("folder")) {
                fileType = "folder";
              } else if (currentObject.mimeType.includes("sheet")) {
                fileType = "sheet";
              }
              let objCopy = currentObject as any;
              objCopy.fileType = fileType;
              if (!prev.find((element) => element?.sourceData?.id === id)) {
                idTracker[id] = true;
                result.push({
                  name: currentObject.name,
                  sourceType: "google-drive",
                  sourceData: objCopy,
                });
              }
            }
            return [...prev, ...result];
          });
          setOpened("");
        }
      },
    });
  };

  useEffect(() => {
    if (opened === "google-drive" && token) {
      openGooglePicker();
    }
  }, [opened, token]);

  return (
    <>
      <CreatePage.BackButton onClick={() => setConfirmedDataSource("")}>
        <FontAwesomeIcon
          style={{ marginRight: "5px" }}
          icon={faArrowLeftLong}
          size="1x"
          color={theme.colors.primary.main}
        />
        Back
      </CreatePage.BackButton>
      <LayoutStyles.LibrarySection.TitleSection style={{ marginBottom: 60 }}>
        <LayoutStyles.LibrarySection.LibraryTitle style={{ fontSize: 20 }}>
          <LayoutStyles.LibrarySection.IntegrationIcon
            src={intDisplayData[source].logo}
          />
          Google Drive
        </LayoutStyles.LibrarySection.LibraryTitle>
        <LayoutStyles.LibrarySection.UploadButton
          onClick={() => {
            setOpened("");
            setOpened("google-drive");
          }}
        >
          <FontAwesomeIcon icon={faCircleArrowUp} style={{ height: 18 }} />
          Upload files
        </LayoutStyles.LibrarySection.UploadButton>
      </LayoutStyles.LibrarySection.TitleSection>
      {uploadedFiles.length > 0 && (
        <CreatePage.DocumentGrid
          container
          spacing="20px"
          justifyContent="center"
          alignItems="center"
          width="90%"
          maxWidth="1300px"
          padding="0 0 50px 20px"
        >
          {selectedUrls
            .filter((source) => source.sourceType === "google-drive")
            .map((source, index) => (
              <LayoutStyles.CreatePage.GridItem
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                key={index}
              >
                <div
                  style={{ position: "relative", width: "100%" }}
                  onMouseOver={() => setHoverIdx(index)}
                  onMouseOut={() => setHoverIdx(-1)}
                >
                  {index === hoverIdx && (
                    <LayoutStyles.CreatePage.DeleteButton
                      onClick={() => {
                        setSelectedUrls((prev) =>
                          prev.filter((_, idx) => idx !== index)
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </LayoutStyles.CreatePage.DeleteButton>
                  )}
                  <DocCard
                    maxWidth={"unset"}
                    name={source.name}
                    lastEdited={source.sourceData?.lastEditedUtc as number}
                    fileType={source.sourceData.fileType}
                    uploaded={true}
                    synced={true}
                  />
                </div>
              </LayoutStyles.CreatePage.GridItem>
            ))}
        </CreatePage.DocumentGrid>
      )}
      <LayoutStyles.CreatePage.ContinueButton
        onClick={async () => {
          if (selectedUrls.length > 0) {
            if (isProcessing) return;
            setProcessing(true);

            await initiateBotCreation(
              selectedUrls,
              { ...customStyles, name: companyName },
              () => {}
            );
            navigate("/data");
          } else {
            console.log("select at least one option");
          }
        }}
        $enabled={!!(selectedUrls.length > 0)}
      >
        Continue
        <FontAwesomeIcon size="xl" icon={faArrowCircleRight} />
      </LayoutStyles.CreatePage.ContinueButton>
      <div ref={dropdownRef}></div>
    </>
  );
};

export default ThirdPartyUpload;
