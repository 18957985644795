import React, { useState } from "react";
import LayoutStyles from "../App.styles";
import { useTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import WebIcon from "../assets/icons/website.png";
import { useClient } from "../context/ClientContext";
import { useNavigate } from "react-router-dom";
import { CustomStyles } from "./OnboardCustomizer/Customizer";

type Props = {
  urls: string[];
  setSelectedUrls: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        sourceType: string;
        sourceData: { [key: string]: any };
      }[]
    >
  >;
  selectedUrls: {
    name: string;
    sourceType: string;
    sourceData: { [key: string]: any };
  }[];
  website: string;
  showContinue?: boolean;
  customStyles?: CustomStyles;
};
const UrlMenu = ({
  urls,
  setSelectedUrls,
  selectedUrls,
  website,
  customStyles,
  showContinue = true,
}: Props) => {
  const [favicon, setFavicon] = useState(
    website.startsWith("http")
      ? `${website}/favicon.ico`
      : `https://${website}/favicon.ico`
  );
  const [isProcessing, setProcessing] = useState(false);
  const { initiateBotCreation, companyName } = useClient();
  const navigate = useNavigate();
  const theme: any = useTheme();
  
  const handleSelectAll = () => {
    let sortedUrls = urls.sort();
    let sortedSelectedUrls = selectedUrls.map((el) => el.sourceData.url).sort();
    let arraysAreEqual = sortedUrls.every((url, index) => {
      return sortedUrls[index] === sortedSelectedUrls[index];
    });
    if (arraysAreEqual && urls.length !== 0) {
      setSelectedUrls([]);
    } else {
      setSelectedUrls(
        urls.map((el) => {
          return {
            name: el,
            sourceType: "website",
            sourceData: {
              url: el,
            },
          };
        })
      );
    }
  };

  const handleChange = (
    event: React.FormEvent<HTMLInputElement>,
    url: string
  ) => {
    if (!selectedUrls.some((el) => el.sourceData.url === url)) {
      const urlObj = {
        name: url,
        sourceType: "website",
        sourceData: {
          url: url,
        },
      };
      setSelectedUrls((prev) => [...prev, urlObj]);
    } else {
      setSelectedUrls((prev) =>
        prev.filter((element) => element.sourceData.url !== url)
      );
    }
  };

  return (
    <>
      <LayoutStyles.UrlMenuContainer>
        <LayoutStyles.FlexRow
          $margin="0 0 29px"
          style={{ justifyContent: "center" }}
        >
          <LayoutStyles.Favicon
            onError={() => setFavicon(WebIcon)}
            src={favicon}
          />
          <LayoutStyles.FlexColumn style={{ maxWidth: "400px" }}>
            <LayoutStyles.UrlHeader>{website}</LayoutStyles.UrlHeader>
            <LayoutStyles.WebpageCount>
              We found {urls.length} relevant web pages
            </LayoutStyles.WebpageCount>
          </LayoutStyles.FlexColumn>
        </LayoutStyles.FlexRow>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#F1E6E0",
            margin: "0 0 15px 0",
          }}
        />
        <LayoutStyles.WebsiteContainer>
          <LayoutStyles.UrlBox >
            <LayoutStyles.UrlCheckBox
              id={`import-all`}
              type="checkbox"
              onClick={() => handleSelectAll()}
              />
            <LayoutStyles.UrlCirceCheckBox
              $checked={
                selectedUrls.length > 0 &&
                urls.every(
                  (url) =>
                    !!selectedUrls.find((el) => el.sourceData.url === url)
                )
              }
              htmlFor={`import-all`}
            >
              <FontAwesomeIcon
                icon={faCheck}
                color="#fff"
              />
            </LayoutStyles.UrlCirceCheckBox>
            <LayoutStyles.FlexRow>
              <label htmlFor={`import-all`}>Import all web pages</label>
            </LayoutStyles.FlexRow>
          </LayoutStyles.UrlBox>
          {urls.map((element, index) => {
            let formattedUrl = element.startsWith("http")
              ? element
              : "https://" + element;
            const urlObject = new URL(formattedUrl);
            const pathname = urlObject.pathname;
            const lastPart = pathname.substring(pathname.lastIndexOf("/") + 1);
            const reconstructedUrl =
              urlObject.origin +
              pathname.substring(0, pathname.lastIndexOf("/") + 1);

            return (
              <LayoutStyles.UrlBox
                key={index}
              >
                <LayoutStyles.UrlCheckBox
                  id={`website-page-${index}`}
                  type="checkbox"
                  onChange={(e) => handleChange(e, element)}
                  />
                <LayoutStyles.UrlCirceCheckBox
                  $checked={
                    !!selectedUrls.some((el) => el.sourceData.url === element)
                  }
                  htmlFor={`website-page-${index}`}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#fff"
                  />
                </LayoutStyles.UrlCirceCheckBox>
                <LayoutStyles.FlexRow>
                  <label htmlFor={`website-page-${index}`}>
                    {reconstructedUrl.slice(8)}
                    <span
                      style={{
                        color: theme.colors.primary.main,
                        fontWeight: "500",
                      }}
                    >
                      {lastPart}
                    </span>
                  </label>
                </LayoutStyles.FlexRow>
              </LayoutStyles.UrlBox>
            );
          })}
        </LayoutStyles.WebsiteContainer>
      </LayoutStyles.UrlMenuContainer>
      {showContinue && (
        <LayoutStyles.CreatePage.ContinueButton
          $enabled={selectedUrls.length > 0}
          disabled={selectedUrls.length === 0}
          onClick={async () => {
            if (selectedUrls.length > 0) {
              if (isProcessing || !customStyles) return;
              setProcessing(true);

              console.log("selected", selectedUrls);
              await initiateBotCreation(
                selectedUrls,
                { ...customStyles, name: companyName },
                () => {}
              );
              navigate("/data");
            }
          }}
        >
          Continue
          <FontAwesomeIcon
            size="xl"
            icon={faArrowCircleRight}
          />
        </LayoutStyles.CreatePage.ContinueButton>
      )}
    </>
  );
};

export default UrlMenu;
