import { Dialog, useMediaQuery } from "@mui/material";
import { useState, FormEventHandler, useEffect } from "react";
import { LoginStyles } from "../LoginStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faArrowCircleRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import logoIcon from "../../../assets/images/branding/infobox/logoIcon.png";
import { useClient } from "../../../context/ClientContext";
import { useTheme } from "styled-components";
import { resetPassword } from "../../../actions";
import SplashStyles from "../../../pages/SplashPage/SplashStyles";

const ForgotPasswordModal = () => {
  const theme: any = useTheme();
  const { setForgotOpen, setLoginOpen } = useClient();
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);
  const [forgotForm, setForgotForm] = useState(true);
  const [confirmEmailSent, setConfirmEmailSent] = useState(false);

  const validate = (values: { email: string }) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = "This field is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const forgotFormModalHandler = () => {
    setForgotForm(false);
    setConfirmEmailSent(true);
  };

  const forgotFormHandler: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!formik.values.email) return;
    forgotFormModalHandler();
    await resetPassword({ email: formik.values.email });
  };

  const openLogin = () => {
    setForgotOpen(false);
    setConfirmEmailSent(false);
    setLoginOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    console.log(formik.errors);
  }, [formik.errors]);
  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={true}
      sx={{
        "& .MuiPaper-root": { borderRadius: isSmallScreen ? "0" : "34px" },
      }}
    >
      {forgotForm && (
        <LoginStyles.Container
          style={{
            maxWidth: isSmallScreen ? "unset" : "492px",
            width: "100%",
            height: "100%",
            maxHeight: isSmallScreen ? "unset" : "423px",
            padding: "40px",
            overflow: "hidden",
            marginBottom: '0'
          }}
        >
          <LoginStyles.BackToSignInButton onClick={openLogin}>
            <FontAwesomeIcon style={{marginRight: '5px'}} icon={faChevronLeft} size="1x" />
            <p>Back to sign in</p>
          </LoginStyles.BackToSignInButton>
          <SplashStyles.FlexColumn
            style={{
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <img
              className="logo"
              src={logoIcon}
              alt="logo-icon"
              style={{
                marginTop: "40px",
              }}
            />
            <LoginStyles.CloseButton onClick={() => setForgotOpen(false)}>
              <FontAwesomeIcon icon={faXmark} />
            </LoginStyles.CloseButton>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              style={{
                textAlign: "center",
                padding: "20px",
              }}
            >
              <h1>Forgot your password?</h1>
              <p>
                Enter the email address associated with your account and we'll
                send you a link to reset your password.
              </p>

              <LoginStyles.TextFieldWrapper
                style={{
                  margin: "15px",
                }}
              >
                <LoginStyles.TextField
                  placeholder="Email"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="errors">{formik.errors.email}</div>
                )}
              </LoginStyles.TextFieldWrapper>
              <LoginStyles.SubmitButton
                $isValid={formik.isValid}
                $dirty={formik.dirty}
                type="submit"
                onClick={forgotFormHandler}
              >
                <div>Submit</div>
                <FontAwesomeIcon icon={faArrowCircleRight} size="xl" />
              </LoginStyles.SubmitButton>
            </form>
          </SplashStyles.FlexColumn>
        </LoginStyles.Container>
      )}

      {confirmEmailSent && (
        <LoginStyles.Container
          style={{
            maxWidth: "492px",
            width: "100%",
            maxHeight: "423px",
            padding: "40px",
            overflow: "hidden",
            marginBottom: '0'
          }}
        >
          <LoginStyles.BackToSignInButton onClick={openLogin}>
            <FontAwesomeIcon style={{marginRight: '5px'}} icon={faChevronLeft} size="1x" />
            <p>Back to sign in</p>
          </LoginStyles.BackToSignInButton>
          <img
            className="logo"
            src={logoIcon}
            alt="logo-icon"
            style={{
              marginTop: "40px",
            }}
          />
          <LoginStyles.CloseButton onClick={() => setForgotOpen(false)}>
            <FontAwesomeIcon icon={faXmark} />
          </LoginStyles.CloseButton>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              minWidth: "492px",
              minHeight: "423px",
              padding: "40px 97px",
            }}
          >
            <h1>An email was sent</h1>
            <p>
              We sent an email to {formik.values.email}. Please check your inbox
              and click the link to reset your password.
            </p>
            <p
              style={{
                paddingTop: "100px",
              }}
            >
              Didn't receive an email?{" "}
              <a href="" onClick={forgotFormHandler}>
                Resend email.
              </a>
            </p>
          </div>
        </LoginStyles.Container>
      )}
    </Dialog>
  );
};

export default ForgotPasswordModal;
